import React, { useState } from 'react'
import Header from '../components/header'
import Chart from '../components/charts'


const Supply = () => {



    const [formData, setFormData] = useState({
        typeofcompany: '',
        supplytype: '',
        companyfullname: '',
        companyaddress: '',
        email: '',
        phone: '',
        supplyport: '',
        country: '',
        additionalcomments: '',
      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await fetch('/supplier-form-detail', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
        //   console.log(response)
    
          if (response.ok) {
            console.log('Data inserted successfully');
            setFormData({
                swaps: '',
                supplytype: '',
                companyfullname: '',
                email: '',
                phone: "",
                companyaddress: '',
                supplyport: '',
                country: '',
                additionalcomments: '',
              });
            window.alert("Data Saved!")
            // Optionally, you can reset the form or perform other actions after a successful request
          } else {
            window.alert("Failed to insert data")
            console.error('Failed to insert data');
          }
        } catch (error) {
          console.error('Error posting data:', error.message);
          
        }
      };







    return (
        <div>
            <Header />

            <div style={{ backgroundColor: "#00001c" }}>

                {/* volatility section  */}
                <div>
                    <div className='row mx-3 market-top just text-align-justify mx-lg-5 px-0'>
                        <div style={{ paddingTop: "40px" }} className='col-md-6 blw-swipe px-0 text-white first-sec'>
                            <h1 className='mb-4'>Supplier</h1>
                            <p className='py-0'>
                            Working with large buyers and sellers we believe we could add value to the ever-growing demand of hedging in the marine industry, working with well known and regulated derivatives traders we are able to offer our suppliers the option for hedging, carbon credits and all clearing arrangements whether it be for the cargo purchased by you or to offering an FFP contract to your well established clients.
                            <br/><br/>Kindly complete our form below and one of our brokers will be in touch with you
                            </p>
                        </div>
                        <div className='col-md-6 position-relative'>
                            <div className='text-center text-md-end'>
                                <img className='top-sup' src="./images/tanker.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section Divider  */}
                <div className='w-100' style={{ backgroundColor: "#FF8C00", height: "0.2rem" }}></div>


                {/* Details Section  */}
                <div className="row justify-center">
                    {/* <div className='col pt-5 mx-3 mx-lg-5'>
                        <span className='fs-3 text-white'> <span style={{ borderBottom: "2px solid #FF8C00" }}>What</span> we offer</span>
                        <p className='text-white pt-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis, ipsa? Magni corporis officiis quam laudantium harum consectetur, sit neque quia quidem repellat voluptatibus totam sapiente aliquid deserunt nulla velit sed. Quo quisquam culpa, inventore, quae ipsum, voluptates animi quasi ullam repellendus quis minus suscipit dolores! Labore, velit vel est quia dolor totam. Dolorum inventore a, cum ullam, reiciendis ipsam consectetur labore itaque earum numquam corrupti iure, laboriosam ea modi illo! Adipisci obcaecati, fuga reiciendis voluptas alias quia repellat ratione magnam doloremque officia commodi voluptate impedit iure, animi aliquam sint repudiandae qui ducimus ex, molestiae eum voluptatibus eligendi nostrum. Quisquam, minima. </p>

                        <ul className='text-white pt-3 pb-5'>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae, iure?</li>
                            <li className='pt-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae, iure?</li>
                            <li className='pt-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae, iure?</li>
                            <li className='pt-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae, iure?</li>
                        </ul>
                    </div> */}
                    <div className="col-5 dnone-phonescreen">
                        <div style={{height: "100%"}} className="reg-img imagesupplyform"></div>
                    </div>
                    <div style={{border: " 3px solid #FF8C00"}} className='col-sm-8 col-md-5 my-5 mx-auto p-4 supply-form-inner-col'>
                                <div className='mt-2'>
                                    <p className='text-white' style={{ fontSize: "30px" }}>Supplier Form</p>
                                    <form className='pt-3 text-white' onSubmit={handleSubmit}>
                                    <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Company Full name</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.companyfullname} id='companyfullname' name='companyfullname' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Company Address</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.companyaddress} id='companyaddress' name='companyaddress' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Email</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.email} id='email' name='email' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Phone</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.phone} id='phone' name='phone' onChange={handleChange} className='w-100 p-1' type="number" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Supply Port</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.supplyport} id='supplyport' name='supplyport' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Country</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.country} id='country' name='country' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Swaps</p>
                                            <select style={{ backgroundColor: "#00001c", border: "1px solid #ff8c00" }}onChange={handleChange} value={formData.swaps} id='swaps' name='swaps'  className='w-100 p-1 text-white'>
                                                <option value="null">Select Swaps</option>
                                                <option value="Rotts 3.5%">Rotts 3.5%</option>
                                                <option value="Rotts 0.5%">Rotts 0.5%</option>
                                                <option value="NWE 1% FOB">NWE 1% FOB</option>
                                                <option value="Sing 380cst">Sing 380cst</option>
                                                <option value="Sing 0.5%">Sing 0.5%</option>
                                                <option value="USGC 3%">USGC 3%</option>
                                                <option value="USGC 0.5%">USGC 0.5%</option>
                                                <option value="Gasoil: Sing 10ppm">Gasoil: Sing 10ppm</option>
                                                <option value="Rotts 0.1%">Rotts 0.1%</option>
                                            </select>
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Supply type</p>
                                            <select style={{ backgroundColor: "#00001c", border: "1px solid #ff8c00" }}onChange={handleChange} value={formData.supplytype} id='supplytype' name='supplytype'  className='w-100 p-1 text-white'>
                                                <option value="null">Select Supply Type</option>
                                                <option value="Barge supply">Barge supply</option>
                                                <option value="Truck supply">Truck supply</option>
                                                <option value="Cargo">Cargo</option>
                                                <option value="Tank owner">Tank owner</option>
                                                <option value="Tank operator">Tank operator</option>
                                                <option value="Commodity trader">Commodity trader</option>
                                            </select>
                                        </div>
                                        <div className="mb-4">
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Additional Comments</p>
                                            <textarea rows={4} style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.additionalcomments} id='additionalcomments' name='additionalcomments' onChange={handleChange} className='w-100 p-1' type="text" />
                                            {/* <textarea style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }} id='phoneno' name='phoneno' onChange={handleChange} className='w-100 p-1' type="number" /> */}
                                        </div>
                                        <div style={{ paddingTop: "0.8rem" }}>
                                            <button type="submit" style={{ backgroundColor: "#ff8c00" }} className="btn-11 nav-but" ><span style={{ zIndex: 3, position: 'relative' }}>Submit</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                </div>

                {/* <div className='mx-3 pb-5 mx-lg-5'>
                    <button className='text-white py-2 px-4' style={{ backgroundColor: "#FF8C00", border: "none" }}>Click For Quote</button>
                </div> */}

            </div>
        </div>
    )
}

export default Supply