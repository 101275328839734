import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

export default function Chart1() {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await fetch("/getgraphmgodata", {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        let result = await response.json();

        const uniqueCountries = [...new Set(result.map(entry => entry.countryName))];
        const header = ["Days", ...uniqueCountries];
        const groupedData = result.reduce((acc, entry) => {
          const { date, countryName, MGO } = entry;
          if (!acc[date]) {
            acc[date] = { date };
          }
          acc[date][countryName] = MGO;
          return acc;
        }, {});

        const finalData = [header, ...Object.values(groupedData).map(entry => Object.values(entry))];
        const today = new Date();
        const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, today.getDate());
        
        finalData[1][0] = `${twoMonthsAgo.getDate()} ${getMonthName(twoMonthsAgo.getMonth())}`; // 2 months ago
        finalData[2][0] = `${oneMonthAgo.getDate()} ${getMonthName(oneMonthAgo.getMonth())}`; // 1 month ago
        finalData[3][0] = `${today.getDate()} ${getMonthName(today.getMonth())}`; // Today's date
        console.log(finalData)
        setChartData(finalData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const options = {
    legend: { position: "top" },
    hAxis: { title: "Date", titleTextStyle: { color: "white", bold: true } },
    vAxis: { title: "Price Range", titleTextStyle: { color: "white", bold: true }, minValue: 0 },
    chartArea: { width: "80%" },
    pointsVisible: true,
    color: "white",
    backgroundColor: "black"
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Chart
      chartType="AreaChart"
      width="100%"
      height="400px"
      data={chartData}
      options={options}
    />
  );
}

function getMonthName(monthIndex) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[monthIndex];
}