import React, { useState } from 'react';

const Credittest = () => {
  const [formData, setFormData] = useState({
    typeofcompany: '',
    creditperiod: '',
    companyname: '',
    companyaddress: '',
    position: '',
    email: '',
    phoneno: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:5000/credit-form-details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Data inserted successfully');
        setFormData({
            typeofcompany: '',
            creditperiod: '',
            companyname: '',
            companyaddress: '',
            position: '',
            email: '',
            phoneno: '',
          });
        window.alert("Data Saved!")
        // Optionally, you can reset the form or perform other actions after a successful request
      } else {
        window.alert("Failed to insert data")
        console.error('Failed to insert data');
      }
    } catch (error) {
      console.error('Error posting data:', error.message);
      
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Type of Company:
        <input type="text" name="typeofcompany" value={formData.typeofcompany} onChange={handleChange} />
      </label>
      <br />

      <label>
        Credit Period:
        <input type="text" name="creditperiod" value={formData.creditperiod} onChange={handleChange} />
      </label>
      <br />
      <label>
        Company Name:
        <input type="text" name="companyname" value={formData.companyname} onChange={handleChange} />
      </label>
      <br />
      <label>
        Credit address:
        <input type="text" name="companyaddress" value={formData.companyaddress} onChange={handleChange} />
      </label>
      <br />
      <label>
        Credit position:
        <input type="text" name="position" value={formData.position} onChange={handleChange} />
      </label>
      <br />
      <label>
        Credit email:
        <input type="text" name="email" value={formData.email} onChange={handleChange} />
      </label>
      <br />
      <label>
        Credit phone:
        <input type="number" name="phoneno" value={formData.phoneno} onChange={handleChange} />
      </label>
      <br />

      {/* ... other input fields ... */}

      <button type="submit">Submit</button>
    </form>
  );
};

export default Credittest;
