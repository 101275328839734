import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CheckLivePrice = () => {
    const [token, setToken] = useState([])
    // const [pricedata, setPriceData] = useState()
    const [reqdata, setReqData] =useState([])
    async function getTockens() {
        let data = await fetch(`/accesstoken`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        })
    
        let res = await data.json()
        // console.log(res)
    
        setToken(res)
      }
      
      

      useEffect(() => {
        getTockens()
      }, []);

      useEffect(() => {
        // getDataFromToken()
        async function getDataFromToken(){
            let getdata = await fetch(`/getdatafrom`, {
                method: "POST",
                // body: JSON.stringify({ token: token[0].access_token}),
                body: JSON.stringify({ token: token}),
                headers:{
                    "Content-Type":"application/json"
                }
            })
            // let res = await getdata.json()
            // console.log("data31",res) 

        const jsonData = await getdata.json();

        // Log the entire response to inspect its structure
        // console.log('Response:', jsonData);

        // Check if payload exists before accessing it
        if (jsonData && jsonData.payload) {
          const results = Object.keys(jsonData.payload).map((entryKey) => {
            const entry = jsonData.payload[entryKey];
            return {
              name: entry.name,
              Z23: entry.data?.Z23?.value
            };
          })
          setReqData(results);
            // setPriceData(res)
          }}
          getDataFromToken()
      }, [token]);
console.log(reqdata)
  return (
    <div>
        {/* {token} */}
      {/* <h1>Access Token: {token.accessToken}</h1> */}
      {/* Your component JSX */}
    </div>
  );
  };
export default CheckLivePrice; 

// export default CheckLivePrice;
