import React, { useEffect, useRef, useState } from 'react'
import "../home.css"
import Globe from 'react-globe.gl';
import Login from '../components/login';
import Header from '../components/header';


const Bunker = () => {

    
    const [price, setPrice] = useState([])

    async function getPrices() {
        let data = await fetch(`/bunkerprice`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        })
    
        let res = await data.json()
        // console.log(res)
    
        setPrice(res)
      }
      useEffect(() => {
        getPrices()
        globeEl.current.controls().enableZoom = false;
        globeEl.current.controls().autoRotate = true;
        globeEl.current.controls().autoRotateSpeed = 1;
      }, [10]);
    const mgoValues = {};

    price.forEach(({ countryName, MGO }) => {
        mgoValues[countryName] = MGO;
    });
    // console.log(mgoValues)
    // const singaporeMGO = mgoValues['Singapore'];
    // const rotterdamMGO = mgoValues['Rotterdam'];
    // const houstonMGO = mgoValues['Houston'];
    // const fujairahMGO = mgoValues['Fujairah'];
    // const laLongBeachMGO = mgoValues['LA/ Long Beach'];
    // const hongKongMGO = mgoValues['Hong Kong'];
    // const santosMGO = mgoValues['Santos'];
    // console.log(`MGO value for Singapore: ${singaporeMGO}`);

    const singaporeMGO = mgoValues['Singapore'];
    const rotterdamMGO = mgoValues['Rotterdam'];
    const anntwerpMGO = mgoValues['Anntwerp'];
    const hamburgMGO = mgoValues['Hamburg'];
    // const falmouthMGO = mgoValues['Falmouth'];
    const gibraltarMGO = mgoValues['Gibraltar'];
    const maltaMGO = mgoValues['Malta'];
    const genoaMGO = mgoValues['Genoa'];
    const piraeusMGO = mgoValues['Piraeus'];
    const istanbulMGO = mgoValues['Istanbul'];
    const lasPalmasMGO = mgoValues['Las Palmas'];
    const jeddahMGO = mgoValues['Jeddah'];
    // const dammanMGO = mgoValues['Damman'];
    const fujairahMGO = mgoValues['Fujairah'];
    const capeTownMGO = mgoValues['Cape Town'];
    const durbanMGO = mgoValues['Durban'];
    const mumbaiMGO = mgoValues['Mumbai'];
    const kochiMGO = mgoValues['Kochi'];
    const colomboMGO = mgoValues['Colombo'];
    const hongKongMGO = mgoValues['Hong Kong'];
    const busanMGO = mgoValues['Busan'];
    const yosuMGO = mgoValues['Yosu'];
    const newYorkMGO = mgoValues['New York'];
    const seattleMGO = mgoValues['Seattle'];
    const vancouverMGO = mgoValues['Vancouver'];
    const panamaMGO = mgoValues['Panama'];

    let globeEl = useRef(null)
    const [places, setPlaces] = useState([]);

async function getglobedata() {
    let globedata = [
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            // "natscale": 600,
            "natscale": 100,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${singaporeMGO}`,
            "namepar": "Singapore",
            "namealt": null,
            "diffascii": 0,
            "nameascii": "Singapore",
            "adm0cap": 0,
            "capalt": null,
            "capin": null,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Singapore",
            "sov_a3": "Singapore",
            "adm0name": "Singapore",
            "adm0_a3": "Singapore",
            "adm1name": "Singapore",
            "iso_a2": "SI",
            "note": null,
            "latitude": 1.3521,
            "longitude": 103.8198,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            // "pop_max": 4505689,
            "pop_min": 131392,
            "pop_max": 14787000,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 1,
            "geonameid": 1275004,
            // "meganame": "Singapore",
            // "ls_name": "Calcutta",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            1.3521, 
            103.8198,
            1.3521, 
            103.8198
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                1.3521, 
                103.8198
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${rotterdamMGO}`,
            "namepar": "Rotterdam",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Rotterdam",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Rotterdam",
            "sov_a3": "Rotterdam",
            "adm0name": "Rotterdam",
            "adm0_a3": "Rotterdam",
            "adm1name": "Rotterdam",
            "iso_a2": "IN",
            "note": true,
            "latitude": 50.9244,
            "longitude": 4.4777,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            // "pop_max": 14787000,
            // "pop_min": 4631392,
            "pop_max": 4505689,
            "pop_min": 131392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Rotterdam",
            "ls_name": "Rotterdam",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            51.9244, 
            4.4777,
            51.9244, 
            4.4777
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                51.9244, 
                4.4777
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${anntwerpMGO}`,
            "namepar": "Antwerp",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Antwerp",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Antwerp",
            "sov_a3": "Antwerp",
            "adm0name": "Antwerp",
            "adm0_a3": "Antwerp",
            "adm1name": "Antwerp",
            "iso_a2": "IN",
            "note": true,
            "latitude": 47.2194,
            "longitude": 4.4024,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            // "pop_max": 14787000,
            // "pop_min": 4631392,
            "pop_max": 4505689,
            "pop_min": 131392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Antwerp",
            "ls_name": "Antwerp",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            51.2194, 
            4.4024, 
            51.2194, 
            4.4024
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                51.2194, 
                4.4024
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${hamburgMGO}`,
            "namepar": "Hamburg",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Hamburg",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Hamburg",
            "sov_a3": "Hamburg",
            "adm0name": "Hamburg",
            "adm0_a3": "Hamburg",
            "adm1name": "Hamburg",
            "iso_a2": "IN",
            "note": true,
            "latitude": 53.5511,
            "longitude": 9.9937,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_max": 14787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Hamburg",
            "ls_name": "Hamburg",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            53.5511, 
            9.9937, 
            53.5511, 
            9.9937
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                53.5511, 
                9.9937
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${fujairahMGO}`,
            "namepar": "Fujairah",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Fujairah",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Fujairah",
            "sov_a3": "Fujairah",
            "adm0name": "Fujairah",
            "adm0_a3": "Fujairah",
            "adm1name": "Fujairah",
            "iso_a2": "IN",
            "note": true,
            "latitude": 25.1288,
            "longitude": 56.3265,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_max": 14787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Fujairah",
            "ls_name": "Fujairah",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            25.1288, 
            56.3265,
            25.1288, 
            56.3265
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                25.1288, 
                56.3265
            ]
        }
    },
    // {
    //     "type": "Feature",
    //     "properties": {
    //         "scalerank": 0,
    //         "natscale": 600,
    //         "labelrank": 1,
    //         "featurecla": "Admin-1 capital",
    //         "name": `$ ${falmouthMGO}`,
    //         "namepar": "Falmouth",
    //         "namealt": true,
    //         "diffascii": 0,
    //         "nameascii": "Falmouth",
    //         "adm0cap": 0,
    //         "capalt": true,
    //         "capin": true,
    //         "worldcity": 0,
    //         "megacity": 1,
    //         "sov0name": "Falmouth",
    //         "sov_a3": "Falmouth",
    //         "adm0name": "Falmouth",
    //         "adm0_a3": "Falmouth",
    //         "adm1name": "Falmouth",
    //         "iso_a2": "IN",
    //         "note": true,
    //         "latitude": 50.1526,
    //         "longitude": -5.0656,
    //         "changed": 4,
    //         "namediff": 1,
    //         "diffnote": "Name changed. Changed scale rank.",
    //         "pop_max": 14787000,
    //         "pop_min": 4631392,
    //         "pop_other": 7783716,
    //         "rank_max": 14,
    //         "rank_min": 12,
    //         "geonameid": 1275004,
    //         "meganame": "Falmouth",
    //         "ls_name": "Falmouth",
    //         "ls_match": 1,
    //         "checkme": 0,
    //         "min_zoom": 3.7
    //     },
    //     "bbox": [
    //         50.1526, 
    //         -5.0656, 
    //         50.1526, 
    //         -5.0656
    //     ],
    //     "geometry": {
    //         "type": "Point",
    //         "coordinates": [
    //             50.1526, 
    //             -5.0656
    //         ]
    //     }
    // },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${hongKongMGO}`,
            "namepar": "Hong Kong",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Hong Kong",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Hong Kong",
            "sov_a3": "Hong Kong",
            "adm0name": "Hong Kong",
            "adm0_a3": "Hong Kong",
            "adm1name": "Hong Kong",
            "iso_a2": "IN",
            "note": true,
            "latitude": 22.3193,
            "longitude": 114.1694,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_max": 14787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Hong Kong",
            "ls_name": "Hong Kong",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            22.3193, 
            114.1694,
            22.3193, 
            114.1694
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                22.3193, 
                114.1694
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${gibraltarMGO}`,
            "namepar": "Gibraltar",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Gibraltar",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Gibraltar",
            "sov_a3": "Gibraltar",
            "adm0name": "Gibraltar",
            "adm0_a3": "Gibraltar",
            "adm1name": "Gibraltar",
            "iso_a2": "IN",
            "note": true,
            "latitude": 36.1408,
            "longitude": -5.3536,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_max": 14787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Gibraltar",
            "ls_name": "Gibraltar",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            36.1408, 
            -5.3536, 
            36.1408, 
            -5.3536
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                36.1408, 
                -5.3536
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${maltaMGO}`,
            "namepar": "Malta",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Malta",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Malta",
            "sov_a3": "Malta",
            "adm0name": "Malta",
            "adm0_a3": "Malta",
            "adm1name": "Malta",
            "iso_a2": "IN",
            "note": true,
            "latitude": 35.9042,
            "longitude": 14.5189,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            // "pop_max": 14787000,
            // "pop_min": 4631392,
            "pop_max": 4505689,
            "pop_min": 131392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Malta",
            "ls_name": "Malta",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            35.9042, 
            14.5189, 
            35.9042, 
            14.5189
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                35.9042, 
                14.5189 
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${genoaMGO}`,
            "namepar": "Genoa",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Genoa",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Genoa",
            "sov_a3": "Genoa",
            "adm0name": "Genoa",
            "adm0_a3": "Genoa",
            "adm1name": "Genoa",
            "iso_a2": "IN",
            "note": true,
            "latitude": 44.4056,
            "longitude": 8.9463,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_max": 14787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Genoa",
            "ls_name": "Genoa",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            44.4056, 
            8.9463, 
            44.4056, 
            8.9463 
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                44.4056, 
                8.9463
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${piraeusMGO}`,
            "namepar": "Piraeus",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Piraeus",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Piraeus",
            "sov_a3": "Piraeus",
            "adm0name": "Piraeus",
            "adm0_a3": "Piraeus",
            "adm1name": "Piraeus",
            "iso_a2": "IN",
            "note": true,
            "latitude": 37.9475,
            "longitude": 23.6469,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_max": 14787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Piraeus",
            "ls_name": "Piraeus",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            37.9475, 
            23.6469, 
            37.9475, 
            23.6469
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                37.9475, 
                23.6469
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${istanbulMGO}`,
            "namepar": "Istanbul",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Istanbul",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Istanbul",
            "sov_a3": "Istanbul",
            "adm0name": "Istanbul",
            "adm0_a3": "Istanbul",
            "adm1name": "Istanbul",
            "iso_a2": "IN",
            "note": true,
            "latitude": 41.0082,
            "longitude": 28.9784,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_max": 14787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Istanbul",
            "ls_name": "Istanbul",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            41.0082, 
            28.9784, 
            41.0082, 
            28.9784
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                41.0082, 
                28.9784
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${lasPalmasMGO}`,
            "namepar": "Las Palmas",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Las Palmas",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Las Palmas",
            "sov_a3": "Las Palmas",
            "adm0name": "Las Palmas",
            "adm0_a3": "Las Palmas",
            "adm1name": "Las Palmas",
            "iso_a2": "IN",
            "note": true,
            "latitude": 28.1235,
            "longitude": -15.4363,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_max": 14787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Las Palmas",
            "ls_name": "Las Palmas",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            28.1235, 
            -15.4363, 
            28.1235, 
            -15.4363 
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                41.0082, 
                28.9784
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${jeddahMGO}`,
            "namepar": "Jeddah",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Jeddah",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Jeddah",
            "sov_a3": "Jeddah",
            "adm0name": "Jeddah",
            "adm0_a3": "Jeddah",
            "adm1name": "Jeddah",
            "iso_a2": "IN",
            "note": true,
            "latitude": 21.3891, 
            "longitude": 39.8579,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_max": 14787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Jeddah",
            "ls_name": "Jeddah",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            21.3891, 
            39.8579, 
            21.3891, 
            39.8579
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                21.3891, 
                39.8579
            ]
        }
    },
    // {
    //     "type": "Feature",
    //     "properties": {
    //         "scalerank": 0,
    //         "natscale": 600,
    //         "labelrank": 1,
    //         "featurecla": "Admin-1 capital",
    //         "name": `$ ${dammanMGO}`,
    //         "namepar": "Damman",
    //         "namealt": true,
    //         "diffascii": 0,
    //         "nameascii": "Damman",
    //         "adm0cap": 0,
    //         "capalt": true,
    //         "capin": true,
    //         "worldcity": 0,
    //         "megacity": 1,
    //         "sov0name": "Damman",
    //         "sov_a3": "Damman",
    //         "adm0name": "Damman",
    //         "adm0_a3": "Damman",
    //         "adm1name": "Damman",
    //         "iso_a2": "IN",
    //         "note": true,
    //         "latitude": 26.3927,  
    //         "longitude": 49.9777,
    //         "changed": 4,
    //         "namediff": 1,
    //         "diffnote": "Name changed. Changed scale rank.",
    //         "pop_max": 14787000,
    //         "pop_min": 4631392,
    //         "pop_other": 7783716,
    //         "rank_max": 14,
    //         "rank_min": 12,
    //         "geonameid": 1275004,
    //         "meganame": "Damman",
    //         "ls_name": "Damman",
    //         "ls_match": 1,
    //         "checkme": 0,
    //         "min_zoom": 3.7
    //     },
    //     "bbox": [
    //         26.3927, 
    //         49.9777, 
    //         26.3927, 
    //         49.9777
    //     ],
    //     "geometry": {
    //         "type": "Point",
    //         "coordinates": [
    //             26.3927, 
    //             49.9777
    //         ]
    //     }
    // },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${capeTownMGO}`,
            "namepar": "Cape Town",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Cape Town",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Cape Town",
            "sov_a3": "Cape Town",
            "adm0name": "Cape Town",
            "adm0_a3": "Cape Town",
            "adm1name": "Cape Town",
            "iso_a2": "IN",
            "note": true,
            "latitude": 33.918861,
            "longitude": 19.423300,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            // "pop_max": 14787000,
            // "pop_min": 4631392,
            "pop_max": 4505689,
            "pop_min": 131392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Cape Town",
            "ls_name": "Cape Town",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            33.918861, 
            18.423300, 
            33.918861, 
            18.423300
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                33.918861, 
                18.423300
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${durbanMGO}`,
            "namepar": "Durban",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Durban",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Durban",
            "sov_a3": "Durban",
            "adm0name": "Durban",
            "adm0_a3": "Durban",
            "adm1name": "Durban",
            "iso_a2": "IN",
            "note": true,
            "latitude": -29.8587,
            "longitude": 31.0218,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_max": 14787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Durban",
            "ls_name": "Durban",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            -29.8587, 
            31.0218, 
            -29.8587, 
            31.0218
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                -29.8587, 
                31.0218
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${mumbaiMGO}`,
            "namepar": "Mumbai",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Mumbai",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Mumbai",
            "sov_a3": "Mumbai",
            "adm0name": "Mumbai",
            "adm0_a3": "Mumbai",
            "adm1name": "Mumbai",
            "iso_a2": "IN",
            "note": true,
            "latitude": 19.0760,
            "longitude": 72.8777,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_max": 14787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Mumbai",
            "ls_name": "Mumbai",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            19.0760, 
            72.8777, 
            19.0760, 
            72.8777
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                19.0760, 
                72.8777
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${kochiMGO}`,
            "namepar": "Kochi",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Kochi",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Kochi",
            "sov_a3": "Kochi",
            "adm0name": "Kochi",
            "adm0_a3": "Kochi",
            "adm1name": "Kochi",
            "iso_a2": "IN",
            "note": true,
            "latitude": 9.9312,
            "longitude": 76.2673,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            // "pop_max": 14787000,
            // "pop_min": 4631392,
            "pop_max": 7787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Kochi",
            "ls_name": "Kochi",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            9.9312, 
            76.2673, 
            9.9312, 
            76.2673
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                9.9312, 
                76.2673
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${colomboMGO}`,
            "namepar": "Colombo",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Colombo",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Colombo",
            "sov_a3": "Colombo",
            "adm0name": "Colombo",
            "adm0_a3": "Colombo",
            "adm1name": "Colombo",
            "iso_a2": "IN",
            "note": true,
            "latitude": 6.9271,
            "longitude": 79.8612,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            // "pop_max": 14787000,
            // "pop_min": 4631392,
            "pop_max": 4505689,
            "pop_min": 131392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Colombo",
            "ls_name": "Colombo",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            6.9271, 
            79.8612, 
            6.9271, 
            79.8612
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                6.9271, 
                79.8612
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${busanMGO}`,
            "namepar": "Busan",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Busan",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Busan",
            "sov_a3": "Busan",
            "adm0name": "Busan",
            "adm0_a3": "Busan",
            "adm1name": "Busan",
            "iso_a2": "IN",
            "note": true,
            "latitude": 35.1796,
            "longitude": 129.0756,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            // "pop_max": 14787000,
            // "pop_min": 4631392,
            "pop_max": 3505689,
            "pop_min": 131392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Busan",
            "ls_name": "Busan",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            35.1796, 
            129.0756, 
            35.1796, 
            129.0756
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                35.1796, 
                129.0756
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${yosuMGO}`,
            "namepar": "Yosu",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Yosu",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Yosu",
            "sov_a3": "Yosu",
            "adm0name": "Yosu",
            "adm0_a3": "Yosu",
            "adm1name": "Yosu",
            "iso_a2": "IN",
            "note": true,
            "latitude": 34.7604, 
            "longitude": 124.6622,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            // "pop_max": 14787000,
            // "pop_min": 4631392,
            "pop_max": 2505689,
            "pop_min": 131392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Yosu",
            "ls_name": "Yosu",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            34.7604, 
            127.6622, 
            34.7604, 
            127.6622
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                34.7604, 
                127.6622
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${newYorkMGO}`,
            "namepar": "New York",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "New York",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "New York",
            "sov_a3": "New York",
            "adm0name": "New York",
            "adm0_a3": "New York",
            "adm1name": "New York",
            "iso_a2": "IN",
            "note": true,
            "latitude": 40.7128, 
            "longitude": -74.0060,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_max": 14787000,
            "pop_min": 4631392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "New York",
            "ls_name": "New York",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            40.7128, 
            -74.0060,
             40.7128, 
             -74.0060
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                40.7128, 
                -74.0060
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${seattleMGO}`,
            "namepar": "Seattle",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Seattle",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Seattle",
            "sov_a3": "Seattle",
            "adm0name": "Seattle",
            "adm0_a3": "Seattle",
            "adm1name": "Seattle",
            "iso_a2": "IN",
            "note": true,
            "latitude": 47.6062,
            "longitude": -122.3321,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            // "pop_max": 14787000,
            // "pop_min": 4631392,
            "pop_max": 9505689,
            "pop_min": 131392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Seattle",
            "ls_name": "Seattle",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            47.6062, 
            -122.3321, 
            47.6062, 
            -122.3321
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                47.6062, 
                -122.3321
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${vancouverMGO}`,
            "namepar": "Vancouver",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Vancouver",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Vancouver",
            "sov_a3": "Vancouver",
            "adm0name": "Vancouver",
            "adm0_a3": "Vancouver",
            "adm1name": "Vancouver",
            "iso_a2": "IN",
            "note": true,
            "latitude": 52.2827,
            "longitude": -123.1207,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            // "pop_max": 14787000,
            // "pop_min": 4631392,
            "pop_max": 9505689,
            "pop_min": 131392,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Vancouver",
            "ls_name": "Vancouver",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            49.2827, 
            -123.1207, 
            49.2827, 
            -123.1207
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                49.2827, 
                -123.1207
            ]
        }
    },
    {
        "type": "Feature",
        "properties": {
            "scalerank": 0,
            "natscale": 600,
            "labelrank": 1,
            "featurecla": "Admin-1 capital",
            "name": `$ ${panamaMGO}`,
            "namepar": "Panama City",
            "namealt": true,
            "diffascii": 0,
            "nameascii": "Panama City",
            "adm0cap": 0,
            "capalt": true,
            "capin": true,
            "worldcity": 0,
            "megacity": 1,
            "sov0name": "Panama City",
            "sov_a3": "Panama City",
            "adm0name": "Panama City",
            "adm0_a3": "Panama City",
            "adm1name": "Panama City",
            "iso_a2": "IN",
            "note": true,
            "latitude": 8.9831,
            "longitude": -79.5197,
            "changed": 4,
            "namediff": 1,
            "diffnote": "Name changed. Changed scale rank.",
            "pop_min": 131392,
            "pop_max": 14787000,
            "pop_other": 7783716,
            "rank_max": 14,
            "rank_min": 12,
            "geonameid": 1275004,
            "meganame": "Panama City",
            "ls_name": "Panama City",
            "ls_match": 1,
            "checkme": 0,
            "min_zoom": 3.7
        },
        "bbox": [
            8.9831, 
            -79.5197, 
            8.9831, 
            -79.5197
        ],
        "geometry": {
            "type": "Point",
            "coordinates": [
                8.9831, 
                -79.5197
            ]
        }
    }
    ,]

    // console.log(res)

    setPlaces(globedata)
  }
//   console.log(price)

  useEffect(() => {
    // setTimeout(function() {
        getglobedata()
        // console.log("952")
    // }, );
  }, [panamaMGO]);

    return (
        <>
            <div style={{ backgroundColor: "#00001c" }}>
                
                <Header />
                
                {/* Globe Section  */}
                <div>
                    <div style={{ paddingTop: "8rem" }} className='row mx-4 text-align-justify mx-lg-5 px-0'>
                        <div className='col-md-6 blw-swipe px-0 text-white first-sec'>
                            <h1 className='mb-4'>Real Time Pricing</h1>
                            {/* <p className='py-0'>
                            Live Prices are bought to you in partnership with CSC Commodities( Part of the Marex Group). Live Bunker prices based on real times market fixtures along with paper prices updated every 15min, allowing for your purchases to be in real time based on real intelligence.
                            </p> */}
                            <p className='py-0'>
                            Live prices are bought to you in collaboration with our UK FCA regulated partner. Live Bunker Prices are based on real time market deliveries taken place, Paper Prices are on a spot basis with prices being updated every 15min.
                                <br/><br/>One screen all the intelligence needed to compare when making your purchases.
                            </p>
                            <div className='pt-4 pad-but'>
                                <a style={{textDecoration: "none"}} href='/live-prices'><button style={{ backgroundColor: "#ff8c00" }} className="btn-11 py-1 px-3"><span style={{ zIndex: 3, position: 'relative' }}>Check Price</span></button></a>
                                {/* <button style={{ backgroundColor: "#ff8c00" }} className='py-2 px-4 text-white'>Contact Us</button> */}
                            </div>
                        </div>
                        <div className='col-md-6 top-swipe small-dev position-relative'>
                            <div className='position-absolute globe-sec'>
                                <Globe
                                    ref={globeEl}
                                    globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                                    labelsData={places}
                                    labelLat={d => d.properties.latitude}
                                    labelLng={d => d.properties.longitude}
                                    labelText={d => d.properties.name}
                                    labelSize={d => Math.sqrt(d.properties.pop_max) * 4e-4}
                                    labelDotRadius={d => Math.sqrt(d.properties.pop_max) * 4e-4}
                                    labelColor={() => 'rgba(255, 165, 0, 0.75)'}
                                    labelResolution={2}
                                    width={window.innerWidth <= 1100 ? window.innerWidth <= 800 ? 350 : 500 : 950}
                                    height={window.innerWidth <= 1100 ? window.innerWidth <= 800 ? 350 : 500 : 630}
                                    animateIn={true}
                                    backgroundColor='#1C00ff00' />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section Divider  */}
                <div className='w-100' style={{ backgroundColor: "#FF8C00", height: "0.2rem" }}></div>

                {/* Mobile Section  */}
                <div className='px-4' style={{ paddingTop: "5rem" }}>
                    <div className='text-center'>
                        <img className='mx-auto mobile-size' src="./images/tabblur3.png" alt="" />
                    </div>
                    <div className='text-center'>
                        <p className='pt-4' style={{ fontSize: "30pt" }}> <span className='text-white'>One Screen,</span><span style={{ color: "#ff8c00" }}> all the data</span></p>
                        <p className='text-white txt-pad mx-auto pb-5'>No system is able to predict where prices will go, Northsea Trading will come impossibly close…Providing users with all available information and a simple interface making it easier, transparent when making your purchases. In one single screen, users can see composite real time prices and tailor make their purchase with our well trained risk managers and brokers who are one what's app message away.  </p>
                    </div>
                </div>

                {/* Section Divider  */}
                <div className='w-100' style={{ backgroundColor: "#FF8C00", height: "0.2rem" }}></div>

                {/* How it works  */}
                <div className='mx-4 mx-md-0'>
                    <div className='mx-lg-5 mx-md-4 text-white' style={{ paddingTop: "5rem" }}>
                        <p style={{ fontSize: "30pt" }}>How it works ?</p>
                        {/* <p className='txt-pad text-justify'>We can add just the boxes showing register with us with link to register page/ login and link to login page and get started with no link so this way shows just the three boxes.</p> */}
                    </div>
                    <div className='row how mx-md-4 mx-lg-0 pt-md-4 pb-md-5'>
                        <div onMouseEnter={() => {
                            document.getElementById("first").classList.add("rotate")
                            setTimeout(() => {
                                document.getElementById("first").classList.remove("rotate")
                            }, 1000)
                        }} className='col-lg-4 col-md-6 ps-0 mb-4 mb-lg-0'>
                            <a style={{color: "inherit", textDecoration: "none"}} href='/live-prices'>
                            <div className=' text-center gradient-box me-lg-5 sec-box py-4 px-2 p-lg-5'>
                                <img id='first' className='image-rot' src="./images/sign1.png" alt="" />
                                <p className='fs-4 pt-5'>Register with us</p>
                                <p style={{ fontSize: "15px" }}>Free registration</p>
                                {/* <p style={{ fontSize: "15px" }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut libero numquam, doloremque pariatur nesciunt fugit maxime alias? In minima provident consequatur, debitis pariatur et cumque vero repellat illum blanditiis facilis officiis assumenda aperiam. Perferendis aperiam, quod cupiditate modi labore deleniti.</p> */}
                            </div></a>
                        </div>
                        <div onMouseEnter={() => {
                            document.getElementById("second").classList.add("rotate")
                            setTimeout(() => {
                                document.getElementById("second").classList.remove("rotate")
                            }, 1000)
                        }} className='col-lg-4 col-md-6 ps-0 mb-4 mb-lg-0'>
                            <a style={{color: "inherit", textDecoration: "none"}} href='/live-prices'>
                            <div className=' text-center gradient-box me-lg-5 sec-box py-4 px-2 p-lg-5'>
                                <img id='second' className='image-rot' src="./images/sign2.png" alt="" />
                                <p className='fs-4 pt-5'>Login to account</p>
                                <p style={{ fontSize: "15px" }}>Start the journey</p>
                                {/* <p style={{ fontSize: "15px" }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut libero numquam, doloremque pariatur nesciunt fugit maxime alias? In minima provident consequatur, debitis pariatur et cumque vero repellat illum blanditiis facilis officiis assumenda aperiam. Perferendis aperiam, quod cupiditate modi labore deleniti.</p> */}
                            </div></a>
                        </div>
                        <div onMouseEnter={() => {
                            document.getElementById("third").classList.add("rotate")
                            setTimeout(() => {
                                document.getElementById("third").classList.remove("rotate")
                            }, 1000)
                        }} className='col-lg-4 col-md-6 ps-0 mb-4 mb-lg-0'>
                            <a style={{color: "inherit", textDecoration: "none"}} href='/live-prices'>
                            <div className=' text-center gradient-box me-lg-5 sec-box py-4 px-2 p-lg-5'>
                                <img id='third' className='image-rot' src="./images/sign3.png" alt="" />
                                <p className='fs-4 pt-5'>Check the prices</p>
                                <p style={{ fontSize: "15px" }}>At no costs to you</p>
                                {/* <p style={{ fontSize: "15px" }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut libero numquam, doloremque pariatur nesciunt fugit maxime alias? In minima provident consequatur, debitis pariatur et cumque vero repellat illum blanditiis facilis officiis assumenda aperiam. Perferendis aperiam, quod cupiditate modi labore deleniti.</p> */}
                            </div></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bunker