// import React, { createContext, useState } from 'react'


// export const LoginContext = createContext("");

// const Context = ({children}) => {

//     const [logindata,setLoginData] = useState("");

//   return (
//     <>
//     <LoginContext.Provider value={{logindata,setLoginData}}>
//         {children}
//     </LoginContext.Provider>
//     </>
//   )
// }

// export default Context
// LoginContext.js
import React, { createContext, useState } from 'react';

export const LoginContext = createContext();

export const LoginContextProvider = ({ children }) => {
  const [logindata, setLoginData] = useState(null);
  const [isLogged, setLogged] = useState(false); // Add isLogged state

  const updateLoginData = newData => {
    setLoginData(prevData => ({ ...prevData, ...newData }));
  };

  return (
    <LoginContext.Provider value={{ logindata, setLoginData: updateLoginData, isLogged, setLogged }}>
      {children}
    </LoginContext.Provider>
  );
};




