// Headertest.js
import React, { useContext, useEffect, useState } from 'react';
import Login from '../components/login';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../components/ContextProvider/Context';

const Headertest = () => {
  const [data, setData] = useState(false);
  const { logindata, setLoginData, isLogged, setLogged } = useContext(LoginContext);

  const usenav = useNavigate();

  const DashboardValid = async () => {
    let token = localStorage.getItem('usersdatatoken');
    const res = await fetch('/validuser', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    const data = await res.json();

    if (data.status === 401 || !data) {
      console.log('Error Page redirect');
      setLogged(false); // User not logged in
    } else {
      console.log('User Verified');
      setLoginData(data);
      setLogged(true); // User is logged in
    }
  };

  useEffect(() => {
    setTimeout(() => {
      DashboardValid();
      setData(true);
    }, 2000);
  }, []);

  const logoutUser = async () => {
    let token = localStorage.getItem('usersdatatoken');
    const res = await fetch('/logout', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'Accept': 'application/json'
      },
      credentials: 'include'
    });

    const data = await res.json();
    console.log(data);

    if (data.status === 201) {
      console.log('Logout User!');
      localStorage.removeItem('usersdatatoken');
      setLoginData(null);
      setLogged(false); // User is not logged in after logout
      usenav('/home');
    } else {
      console.log('Error');
    }
  };

  return (
    <div style={{ backgroundColor: '#00001c' }}>
      {/* ... rest of your component */}
      <div>
        <ul style={{ listStyle: 'none' }} className="d-flex">
          {!isLogged && <li className="text-white"><Login /></li>}
          {isLogged && <li className="text-white"><button onClick={logoutUser}>Logout</button></li>}
        </ul>
      </div>
    </div>
  );
};

export default Headertest;
