import React, { useContext, useEffect } from 'react';
import "../footer.css";
import Login from "../components/login";
import Register from "./register"
import { LoginContext } from '../components/ContextProvider/Context';



const Footer = () => {


    const [turn, setTurn] = React.useState(false)


    const { logindata, setLoginData, isLogged, setLogged } = useContext(LoginContext);
    const DashboardValid = async () => {
        let token = localStorage.getItem('usersdatatoken');
        const res = await fetch('/validuser', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          }
        });
    
        const data = await res.json();
    
        if (data.status === 401 || !data) {
          // console.log('Error Page redirect');
          setLogged(false); // User not logged in
        } else {
          // console.log('User Verified');
          setLoginData(data);
          setLogged(true); // User is logged in
        //   window.location.reload();
        }
      };
    
      useEffect(() => {
        // setTimeout(() => {
          DashboardValid();
        // }, 2000);
      }, []);

    //   function RegisterButton(){
    //     <Register style={{display: "block"}} />
    // } 


    const logoutUser = async () => {
        let token = localStorage.getItem('usersdatatoken');
        const res = await fetch('/logout', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'Accept': 'application/json'
          },
          credentials: 'include'
        });
    
        const data = await res.json();
        // console.log(data);
    
        if (data.status === 201) {
          // console.log('Logout User!');
          localStorage.removeItem('usersdatatoken');
          setLoginData(null);
          setLogged(false); // User is not logged in after logout
          window.location.reload();
        } else {
          console.log('Error');
        }
      };
    return (
        <>
            <div className='ship'>
                <div className='pt-md-5 pb-3 d-md-flex justify-content-between px-4 px-md-5'>
                    <div className='foot-1'>
                        <ul className='p-0' style={{ listStyle: "none", marginBottom: "50px" }}>
                            <li style={{ color: "#002366" }} className='foot-head'>Quick Contact</li>
                            {/* <li style={{ fontSize: "15px" }} className='mb-2'>If you have any questions or need help, feel free to contact with our team.</li> */}
                            {/* <li className='fw-bold fs-5 mb-3'>+1 1234567890 (24/7)</li> */}
                            <li><p style={{marginBottom: "0"}}>Bunker:</p></li>
                            <li style={{ fontSize: "15px" }} className='d-flex'><span className='me-2'><img width={25} src="./images/email.png" alt="" /></span><a style={{color:"inherit", textDecoration:"none"}} href='mailto:bunkers@Northseatrading.org'><p style={{marginBottom: "0"}}>Bunkers@northseatrading.org</p></a></li>
                            <li><p style={{marginBottom: "0"}}>Paper: </p></li>
                            <li style={{ fontSize: "15px" }} className='d-flex'><span className='me-2'><img width={25} src="./images/email.png" alt="" /></span><a style={{color:"inherit", textDecoration:"none"}} href='mailto:paper@northseatrading.org'><p style={{marginBottom: "0"}}>Paper@northseatrading.org</p></a></li>
                            <li><p style={{marginBottom: "0"}}>Login or IT contact: </p></li>
                            <li style={{ fontSize: "15px" }} className='d-flex'><span className='me-2'><img width={25} src="./images/email.png" alt="" /></span><a style={{color:"inherit", textDecoration:"none"}} href='mailto:IT@northseatrading.org'><p style={{marginBottom: "0"}}>IT@northseatrading.org</p></a></li>
                            <li><p style={{marginBottom: "0", marginTop: "20px"}}>Address: </p></li>
                            <li style={{ fontSize: "15px" }} className='d-flex mb-2'><span className='me-2'><img width={25} src="./images/location.png" alt="" /></span><p  style={{marginBottom: "0"}}>85, Great Portland Street, London, W1W 7LT, Company Registered in England and Wales, Company number: 13895822.</p></li>
                        </ul>
                    </div>
                    <div>
                        <ul className='p-0' style={{ listStyle: "none" }}>
                            <li style={{ color: "#002366" }} className='foot-head'>Company</li>
                            <li style={{ fontSize: "15px" }} className='mb-2 move show '><span><img width={10} src="./images/right.png" alt="" /></span><a style={{textDecoration: "none", color: "#212529"}} href='/live-prices'>Live Prices</a></li>
                            <li style={{ fontSize: "15px" }} className='mb-2 move show '><span><img width={10} src="./images/right.png" alt="" /></span><a style={{textDecoration: "none", color: "#212529"}} href='/credit'>Credit</a></li>
                            <li style={{ fontSize: "15px" }} className='mb-2 move show '><span><img width={10} src="./images/right.png" alt="" /></span><a style={{textDecoration: "none", color: "#212529"}} href='/market'>Market Intelligence </a></li>
                            <li style={{ fontSize: "15px" }} className='mb-2 move show '><span><img width={10} src="./images/right.png" alt="" /></span><a style={{textDecoration: "none", color: "#212529"}} href='/supplier'>Supplier</a></li>
                            <li style={{ fontSize: "15px" }} className='mb-2 move show '><span><img width={10} src="./images/right.png" alt="" /></span><a style={{textDecoration: "none", color: "#212529"}} href='mailto:mailto:legal@northseatrading.org'>Legal services</a></li>
                        </ul>
                    </div>
                    <div>
                        <ul className='p-0' style={{ listStyle: "none" }}>
                            <li style={{ color: "#002366" }} className='foot-head'>Account</li>
                            
                            {!isLogged && <li style={{zIndex:"9"}} className="mb-2 move show footer-login-li "><span><img width={10} src="./images/right.png" alt="" /></span><Login className="bg-transparent" style={{backgroundColor: "transparent !important", color: "black"}} /></li>}
                            {!isLogged && <li style={{zIndex:"9"}} className="mb-2 move show footer-login-li "><span><img width={10} src="./images/right.png" alt="" /></span><button style={{backgroundColor: 'transparent', border: "none" }} onClick={() => {setTurn(true) }}>Register</button></li>}
                            {turn?<Register/>:<div></div>}
                            {isLogged && <li style={{zIndex:"9", color: "#212529"}} className=" mb-2 move show"><span><img width={10} src="./images/right.png" alt="" /></span><button style={{color: "#212529", backgroundColor: "transparent", border: "none"}} onClick={logoutUser}>Logout</button></li>}
                            {/* <li style={{ fontSize: "15px" }} className='mb-2 move show '><span><img width={10} src="./images/right.png" alt="" /></span> <button onClick={loginbutton}>Login</button></li> */}
                            {/* <li style={{ fontSize: "15px" }} className='mb-2 move show '><span><img width={10} src="./images/right.png" alt="" /></span> Register</li> */}
                            <li style={{ fontSize: "15px" }} className='mb-2 move show '><span><img width={10} src="./images/right.png" alt="" /></span> Northsea Trading T&C</li>
                            {/* <li style={{ fontSize: "15px" }} className='mb-2 move show '><span><img width={10} src="./images/right.png" alt="" /></span> Buyer T&Cs</li> */}
                            {/* <li style={{ fontSize: "15px" }} className='mb-2 move show '><span><img width={10} src="./images/right.png" alt="" /></span> Supllier T&Cs</li> */}
                        </ul>
                    </div>
                    <div>
                        <ul className='p-0' style={{ listStyle: "none" }}>
                            <li style={{ color: "#002366" }} className='foot-head'>Follow on Social</li>
                            <div className='d-md-none d-flex'>
                                <li className='mb-2 move show me-3'>
                                {/* <a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='http://facebook.com/northseatrading'><img width={20} src="./images/facebook_ft.png" alt="" /></a> */}
                                </li>
                                <li className='mb-2 move show me-3'>
                                <a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='https://twitter.com/trading_ns'><img width={20} src="./images/twitter_ft.png" alt="" /></a>
                                </li>
                                <li className='mb-2 move show me-3'>
                                <a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='https://www.linkedin.com/company/northseatrading/'><img width={20} src="./images/linkedin_ft.png" alt="" /></a>
                                </li>
                                <li className='mb-2 move show me-3'>
                                <a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='https://www.youtube.com/channel/UCSiPs66HQEXAky7P5toQ_iw'><img width={20} src="./images/youtube_ft.png" alt="" /></a>
                                </li>
                            </div>
                            <div className='d-none d-md-block'>
                                {/* <li style={{ fontSize: "15px" }} className='mb-2 move show '><a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='http://facebook.com/northseatrading'><span className='me-2'><img width={15} src="./images/facebook_ft.png" alt="" /></span>Facebook</a></li> */}
                                <li style={{ fontSize: "15px" }} className='mb-2 move show '><a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='https://twitter.com/trading_ns'><span className='me-2'><img width={15} src="./images/twitter_ft.png" alt="" /></span>Twitter</a></li>
                                <li style={{ fontSize: "15px" }} className='mb-2 move show '><a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='https://www.linkedin.com/company/northseatrading/'><span className='me-2'><img width={15} src="./images/linkedin_ft.png" alt="" /></span>Linkedin</a></li>
                                <li style={{ fontSize: "15px" }} className='mb-2 move show '><a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='https://www.youtube.com/channel/UCSiPs66HQEXAky7P5toQ_iw'><span className='me-2'><img width={15} src="./images/youtube_ft.png" alt="" /></span>Youtube</a></li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div style={{ borderTop: "2px solid #002366" }} className='text-center d-md-none py-3 position-relative'>
                    <div style={{ bottom: "55px" }} className='d-flex w-100 position-fixed justify-content-between'>
                        <div>
                            {/* <img width={60} style={{ top: "-34px" }} className='position-absolute' src="./images/cap.png" alt="" /> */}
                           <a className='position-absolute'l style={{ top: "-34px" }} target='_blank' href='mailto:Bunkers@northseatrading.org'><span><img width={60} src="./images/cap.png" alt="" /></span></a> 
                        </div>
                        <div >
                            {/* <img width={50} style={{ top: "-22px", right: "12px" }} className='position-absolute' src="./images/whatsapp.png" alt="" /> */}
                           <a className='position-absolute'l style={{ top: "-22px", right: "12px" }} target='_blank' href='https://api.whatsapp.com/send?phone=447943870137'><span><img width={50} src="./images/whatsapp.png" alt="" /></span></a> 
                        </div>
                    </div>
                    <p className='m-0' style={{ fontSize: "15px" }}>Copyright © 2023 Northsea Trading, All Rights Reserved.</p>
                </div>
                <div style={{ borderTop: "2px solid #002366" }} className='text-center d-none d-md-block py-3 position-relative'>
                    <p className='m-0' style={{ fontSize: "15px" }}>Copyright © 2023 Northsea Trading, All Rights Reserved.</p>
                    <div style={{ bottom: "45px" }} className='d-flex w-100 position-fixed justify-content-between'>
                        <div style={{ margin: "0 4rem" }}>
                            <a href='mailto:Bunkers@northseatrading.org'><button className='py-2 px-4 rounded-pill position-relative' style={{ backgroundColor: "#1fc4f4", border: "none", fontSize: "15px" }}> <span><img style={{ top: "-34px", left: "-59px" }} className='position-absolute' src="./images/cap.png" alt="" /></span> Send Your Enquiry</button></a>
                        </div>
                        <div style={{ margin: "0 1.5rem" }}>
                           <a target='_blank' href='https://api.whatsapp.com/send?phone=447943870137'><button className='py-2 ps-5 pe-4  rounded-pill position-relative text-white' style={{ backgroundColor: "#64b161", border: "none", fontSize: "15px", lineHeight: 1.2 }}> <span><img style={{ top: "-6px", left: "-22px" }} className='position-absolute' width={60} src="./images/whatsapp.png" alt="" /></span> Contact Your <br /> Broker</button></a> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer