import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";


export const data = [
  ["Days", "Singapore"],
  ["20 Jan", 440,],
  ["21 Jan", 450,],
  ["22 Jan", 460,],
  ["23 Jan", 470,],
];

export default function ChartforPepper() {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await fetch("/extdatafromdb", {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });

        let result = await response.json();
        // console.log(result);
        const headers = ["days", ...result.map(entry => entry.portName)];
        const rows = ["old_Z23", "Z23"].map(property => [property, ...result.map(entry => entry[property])]);
        // const rows = ["new", "old"].map(property => result.map(entry => entry[property + "_Z23"]));

        const data = [headers, ...rows];

        setChartData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }


    fetchData();
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  const options = {
    // isStacked: true,
    legend: { position: "top"},
    // legend: "true",
    hAxis: { title: "Current and Old Price", titleTextStyle: { color: "white", bold: true }, color: "red" },
    vAxis: { title: "Price Range", titleTextStyle: { color: "white", bold: true }, minValue: 0 },
    chartArea: { width: "80%" },
    pointsVisible: true,
    // backgroundColor: "bla",
    color: "white"
  };

  return (
    <Chart
      chartType="AreaChart"
      width="100%"
      style={{backgroundColor: "black"}}
      height="400px"
      data={chartData || data}
      
      options={options}
    />
  );
}

