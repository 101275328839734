import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/header'
import Chart from '../components/charts'
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../components/ContextProvider/Context';
import Login from "../components/login"
import ChartforPepper from '../components/chartForPapper';
import ChartforHFO from '../components/chartForHFO';
import ChartforVLSFO from '../components/chartForVLSFO';


const Live = () => {

  const [data, setData] = useState(false);

  const {logindata, setLoginData} = useContext(LoginContext);
//   console.log(logindata);

  const usenav = useNavigate();

    const DashboardValid = async() =>{
        let token = localStorage.getItem("usersdatatoken");
        // console.log(token);
        const res = await fetch("/validuser",{
          method:"GET",
          headers:{
            "Content-Type":"application/json",
            "Authorization":token
          }
        });

        const data = await res.json();
        // console.log(data);

        if(data.status === 401 || !data){
        //   usenav("/")
        //   console.log("Error Page redirect");

        }else{
          // console.log("User Verified")
          setLoginData(data)
          // usenav("/dashboard")
        }

    }

    // useEffect(()=>{
    //     DashboardValid();
    // },[])

    useEffect(()=>{
    //   setTimeout(()=>{
        DashboardValid();
        setData(true)
    //   }, 2000)
    },[])






    // ------ ----- ----- ---- ----



    const [price, setPrice] = useState([])
    const [updateDate, setUpdateDate] = useState([])
    const [extfromdb, setExtFromDb] = useState([])
    const [extgasoilfromdb, setGasoilExtFromDb] = useState([])
    async function getPrices() {
        let data = await fetch(`/bunkerprice`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        })
    
        let res = await data.json()
        // console.log(res)
    
        setPrice(res)
      }

      async function getdate() {
          let data = await fetch('/fuelupdatetime', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
      
          let updateTime = await data.text();
          updateTime = updateTime.replace(/"/g, '');
          console.log(updateTime)
          setUpdateDate(updateTime)
          }


      useEffect(() => {
        getPrices()
        getdate()
      }, []);


      async function getDataFromDb() {
        let data = await fetch(`/extdatafromdb`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        })
    
        let res = await data.json()
        // console.log(res)
    
        setExtFromDb(res)
      }
      async function getGaspoilDataFromDb() {
        let data = await fetch(`/extgasoildatafromdb`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        })
    
        let res = await data.json()
        // console.log(res)
        const sordedgasoil = res.sort((a, b) => a.srlno - b.srlno);
    
        setGasoilExtFromDb(sordedgasoil)
      }

    //   new 3rd party price data
    const [token, setToken] = useState([])
    const [reqdata, setReqData] =useState([])
    async function getTockens() {
        let onedata = await fetch(`/getaccesstoken`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          }
        })
        let data = await fetch(`/accesstoken`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        })
        let res = await data.json()
        // console.log(res)
        setToken(res)
      }
      
      
      useEffect(() => {
        getTockens()
        getDataFromDb()
        getGaspoilDataFromDb()
      }, []);

      useEffect(() => {
        // getDataFromToken()
        async function getDataFromToken(){
            let getdata = await fetch(`/getdatafrom`, {
                method: "POST",
                // body: JSON.stringify({ token: token[0].access_token}),
                body: JSON.stringify({ token: token}),
                headers:{
                    "Content-Type":"application/json"
                }
            })
            let res = await getdata.json()
            // console.log("data31",res)
        }
          getDataFromToken()
      }, [token]);

    //   For Gas Oil data
    useEffect(() => {
        // getDataFromToken()
        async function getGasoilDataFromToken(){
            let getdata = await fetch(`/getgasoildatafrom`, {
                method: "POST",
                // body: JSON.stringify({ token: token[0].access_token}),
                body: JSON.stringify({ token: token}),
                headers:{
                    "Content-Type":"application/json"
                }
            })
            let res = await getdata.json()
            // console.log("data31",res) 
        }
        getGasoilDataFromToken()
      }, [token]);

      function refreshPage(){
        window.location.reload();
    } 

// console.log(reqdata)
// console.log(extfromdb)
const [selectedElement, setSelectedElement] = useState(0);


// const sortedprice = [...price].sort((a, b) => a.srlno.localeCompare(b.itemId));
const sortedPrice = [...price].sort((a, b) => {
    if (typeof a.srlno === 'string' && typeof b.srlno === 'string') {
      return a.srlno.localeCompare(b.srlno);
    } else {
      // If srlno is not a string, perform a simple comparison
      return a.srlno - b.srlno;
    }
  });
// console.log(sortedPrice);


    return (
        <>
        <div className='heade-in-live-price-page'>
            <Header />
        </div>
        {
            logindata ?
        <div>
            

            <div style={{ backgroundColor: "#000" }}>

                {/* volatility section  */}
                <div>
                    <div style={{ paddingTop: "6rem", paddingBottom: "6rem" }} className='row mx-3 text-align-justify mx-lg-5 px-0'>
                        <div style={{ paddingTop: "40px" }} className='col-md-6 blw-swipe px-0 text-white first-sec'>
                            <h1 className='mb-4'>Live Pricing</h1>
                            <p className='py-0'>
                               {/* Live Prices are bought to you in Collaboration with our UK Regulated (FCA) partner. Bunker prices are updated twice daily to enhance market visibility when making your purchases. Swaps are updated every 15min allowing for your purchases to be in real time with transparent market intelligence. */}
                               Live Prices are bought to you in Collaboration with our UK Regulated (FCA) partner. Bunker prices are updated twice daily to enhance market visibility when making your purchases. Paper prices are updated every 15min allowing for your purchases to be in real time with transparent market intelligence.
                            </p>

                        </div>
                        <div className='col-md-6 position-relative'>
                            <div className='text-center text-md-end'>
                                <img src="./images/volatility.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section Divider  */}
                <div className='w-100' style={{ backgroundColor: "#FF8C00", height: "0.2rem" }}></div>


                {/* Charts Section  */}
                <div style={{ paddingTop: "3rem" }} className='row mx-0 mx-md-auto'>
                    <div className='col-lg-7 col-md-6'>
                        <div className='mx-lg-5 mx-md-4 '>
                            <div className=''>
                                <div className='d-flex justify-content-between'>
                                    <img src="./favicon.png" alt="" />
                                    <a className='mt-auto' style={{textDecoration: 'inherit', backgroundColor: '#ff8c00', color: 'black', paddingInline: "15px", paddingBlock: '5px', borderRadius: '10px'}} href='/benchmark'>Benchmark</a>
                                </div>
                                {/* <br/> */}
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <span className='mt-3' style={{ color: "#FF8C00", fontSize: "20px" }}>Bunker</span>
                                    <span style={{color: 'white', textAlign: 'end', marginTop: 'auto'}}>{updateDate}</span>
                                </div>
                            </div>
                            <table className="table mt-2">
                                <thead>
                                    <tr>
                                        <th className='text-white' scope="col">Ports</th>
                                        <th className='text-white' scope="col">HFO<span style={{fontSize: "10px"}}>&nbsp;$/mt</span></th>
                                        <th className='text-white' scope="col">VLSFO<span style={{fontSize: "10px"}}>&nbsp;$/mt</span></th>
                                        <th className='text-white' scope="col">MGO<span style={{fontSize: "10px"}}>&nbsp;$/mt</span></th>
                                        <th className='text-white' scope='col'>Change<p style={{margin:"0", fontSize: "10px"}}>MGO</p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {sortedPrice.map((element, id) => {
                                    let idValue = id+1;
                                    let mainValue = idValue%2;
                                    let oneValue = 1;
                                    let priceDiff = (element.MGO - element.MGO_old)
                                    // console.log(mainValue)
                                    return (
                                        <tr key={`${id}`} style={mainValue ===  oneValue ? { backgroundColor: "#333349" }: { backgroundColor: "unset" }}>
                                        <th className='text-white fw-normal'>{element.countryName}</th>
                                        <td className='text-white'>$&nbsp;{element.HFO}</td>
                                        <td style={{ color: "#ff0000" }}>$&nbsp;{element.VLSFO}</td>
                                        <td style={{ color: "#00ff00" }}>$&nbsp;{element.MGO}</td>
                                        <td style={priceDiff > 0 ?{ backgroundColor: "#00ff00", color: "white" }:{ backgroundColor: "red", color: "white" } }>{(priceDiff).toFixed(2)}</td>
                                    </tr>
                                    );
                                })
                                }
                                    {/* <tr style={{ backgroundColor: "#333349" }}>
                                        <th className='text-white fw-normal'>Isreal</th>
                                        <td className='text-white'>715.90</td>
                                        <td style={{ color: "#ff0000" }}>710.90</td>
                                        <td style={{ color: "#00ff00" }}>789.89</td>
                                        <td style={{ backgroundColor: "#00ff00", color: "white" }}>-4.50</td>
                                    </tr>
                                    <tr>
                                        <th className='text-white fw-normal'>United Kingdom</th>
                                        <td className='text-white'>715.90</td>
                                        <td style={{ color: "#ff0000" }}>710.90</td>
                                        <td style={{ color: "#00ff00" }}>789.89</td>
                                        <td style={{ backgroundColor: "#ff0000", color: "white" }}>-1.50</td>
                                    </tr> */}
                                </tbody>
                            </table>
                            <div>
                            <button className='rounded' style={{padding: "5px 15px", backgroundColor: "#FF8C00", borderRadius: "10px", backgroundColor: selectedElement === 0 ? '#FF8C00' : 'white' }} onClick={() => setSelectedElement(0)}>HFO</button>
                            <button className='rounded' style={{marginInline: "10px", padding: "5px 15px", backgroundColor: "#FF8C00", borderRadius: "10px",  backgroundColor: selectedElement === 1 ? '#FF8C00' : 'white' }} onClick={() => setSelectedElement(1)}>VLSFO</button>
                            <button className='rounded' style={{padding: "5px 15px", backgroundColor: "#FF8C00", borderRadius: "10px",  backgroundColor: selectedElement === 2 ? '#FF8C00' : 'white' }} onClick={() => setSelectedElement(2)}>MGO</button>
                            {selectedElement === 0 && <div><ChartforHFO /></div>}
                            {selectedElement === 1 && <div><ChartforVLSFO /></div>}
                            {selectedElement === 2 && <div><Chart /></div>}
                            </div>
                            
                            
                            
                        </div>
                        <div style={{ paddingBottom: '5rem', marginInline: "50px"}} className='pe-lg-5'>
                                <div style={{backgroundColor: "#333349", padding: "25px", marginTop: "50px"}} className="disclaimersec">
                                    <h6 className="fw-bold fs-5  disclaimer text-white">Disclaimer : </h6>
                                    <p className="text-white">Northsea Trading produces these live prices for your guidance. 
                                    Actual pricing including delivery costs are subject to each individual enquiry. 
                                    All Live prices are derived from actual physical supplies taken place at each individual port.
                                    America's prices are based on the previous days close. Any ports which are not listed above, please contact our broker for a quote</p>
                                </div>
                            </div>
                    </div>
                    <div className='col-lg-5 mt-5 mt-md-0 col-md-6'>
                        <div className='mx-lg-5 mx-md-4'>
                            <div className='d-flex justify-content-between position-relative'>
                                <div style={{marginBottom: "6rem"}}  className='position-relative'>
                                    <img className='papertopimg' style={{position: "absolute",top: "-12px", left: "-15px", }} src="./images/another_comp.svg" alt="" />
                                    <h2 className='mt-2' style={{ color: "#FF8C00", fontSize: "20px", position: "absolute", top:"66px", left: "0px" }}>Paper</h2>
                                </div>
                                <h2 style={{ color: "#FF8C00", visibility: "hidden" }}>Paper</h2>
                            </div>
                            <table className="table mt-2">
                                <thead>
                                    <tr>
                                        {/* <th style={{ visibility: "hidden" }} colSpan={3} className='text-white' scope="col">Ports</th> */}
                                        <th style={{}} colSpan={3} scope="col"><button onClick={refreshPage} style={{ padding: "5px 15px", backgroundColor: "#FF8C00", borderRadius: "10px"}}><img style={{marginBottom: "2px", padding:"0px 10px 0px 5px", width: "30px"}} src="./images/rotate-right.png" alt="" srcset="" />Refresh</button><br/><span style={{color: "white", fontSize: "10px", paddingInline: "2px"}}>Spot</span></th>
                                        <th className='text-white text-center' scope="col">Price</th>
                                        <th className='text-white text-center' scope='col'>Change</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        extfromdb.map((element, id)=>{
                                            let idValue = id+1;
                                            let mainValue = idValue%2;
                                            let oneValue = 1;
                                            let priceDiff = (element.Z23 - element.old_Z23)
                                            return(
                                                <tr key={id} style={mainValue ===  oneValue ? { backgroundColor: "#333349" }: { backgroundColor: "unset" }}>
                                                    <th colSpan={3} className='text-white fw-normal'>{element.portName}</th>
                                                    <td className='text-start' style={{ color: "#00ff00" }}>$&nbsp;{(element.Z23).toFixed(3)} / {element.unit}</td>
                                                    <td className='text-center' style={priceDiff > 0 ?{ backgroundColor: "#00ff00" }:{backgroundColor: "#ff0000"}}>{(priceDiff.toFixed(1))}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {/* <tr style={{ backgroundColor: "#333349" }}>
                                        <th colSpan={3} className='text-white fw-normal'>Rooterdam 3.5%</th>
                                        <td className='text-center' style={{ color: "#00ff00" }}>715.90</td>
                                        <td className='text-center' style={{ backgroundColor: "#00ff00" }}>1.50</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={3} className='text-white fw-normal'>Rooterdam 0.5%</th>
                                        <td className='text-center' style={{ color: "#00ff00" }}>715.90</td>
                                        <td className='text-center' style={{ backgroundColor: "#ff0000" }}>-2.50</td>
                                    </tr>
                                    <tr style={{ backgroundColor: "#333349" }}>
                                        <th colSpan={3} className='text-white fw-normal'>NWE 1% FoB</th>
                                        <td className='text-center' style={{ color: "#00ff00" }}>715.90</td>
                                        <td className='text-center' style={{ backgroundColor: "#00ff00" }}>4.50</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={3} className='text-white fw-normal'>Singapore 380 cst</th>
                                        <td className='text-center' style={{ color: "#00ff00" }}>715.90</td>
                                        <td className='text-center' style={{ backgroundColor: "#ff0000" }}>-1.50</td>
                                    </tr>
                                    <tr style={{ backgroundColor: "#333349" }}>
                                        <th colSpan={3} className='text-white fw-normal'>Singapore 0.5%</th>
                                        <td className='text-center' style={{ color: "#00ff00" }}>715.90</td>
                                        <td className='text-center' style={{ backgroundColor: "#00ff00" }}>1.50</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={3} className='text-white fw-normal'>USGC 3%</th>
                                        <td className='text-center' style={{ color: "#00ff00" }}>715.90</td>
                                        <td className='text-center' style={{ backgroundColor: "#ff0000" }}>-2.50</td>
                                    </tr>
                                    <tr style={{ backgroundColor: "#333349" }}>
                                        <th colSpan={3} className='text-white fw-normal'>USGC 0.5%</th>
                                        <td className='text-center' style={{ color: "#00ff00" }}>715.90</td>
                                        <td className='text-center' style={{ backgroundColor: "#00ff00" }}>5.50</td>
                                    </tr> */}
                                </tbody>
                            </table>

                            <table className="table mt-5">
                                <thead>
                                    <tr>
                                        <th colSpan={3} className='text-white' scope="col">Gasoil</th>
                                        <th className='text-white text-center' scope="col">Price</th>
                                        <th className='text-white text-center' scope='col'>Change</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        extgasoilfromdb.map((element, id)=>{
                                            let idValue = id+1;
                                            let mainValue = idValue%2;
                                            let oneValue = 1;
                                            let priceDiff = (element.Z23 - element.old_Z23)
                                            return(
                                            <tr style={mainValue ===  oneValue ? { backgroundColor: "#333349" }: { backgroundColor: "unset" }}>
                                                <th colSpan={3} className='text-white fw-normal'>{element.portName}</th>
                                                <td className='text-start' style={{ color: "#00ff00" }}>&nbsp;${(element.Z23.toFixed(3))} / {element.unit}</td>
                                                <td className='text-center' style={priceDiff > 0 ?{ backgroundColor: "#00ff00" }:{backgroundColor: "#ff0000"}}>{(priceDiff.toFixed(1))}</td>
                                            </tr>
                                            )
                                        })
                                    }
                                    {/* <tr>
                                        <th colSpan={3} className='text-white fw-normal'>Rooterdam 0.5%</th>
                                        <td className='text-center' style={{ color: "#00ff00" }}>715.90</td>
                                        <td className='text-center' style={{ backgroundColor: "#ff0000" }}>-2.50</td>
                                    </tr> */}
                                </tbody>
                            </table>

                            <div style={{ paddingBottom: '5rem' }} className='pe-lg-5'>
                                {/* <Chart /> */}
                                <ChartforPepper/>
                            </div>
                            <div style={{ paddingBottom: '5rem' }} className='pe-lg-5'>
                                <div style={{backgroundColor: "#333349", padding: "25px", marginTop: "50px"}} className="disclaimersec">
                                    <h6 className="fw-bold fs-5  disclaimer text-white">Disclaimer : </h6>
                                    <p className="text-white">
                                        Northsea Trading produces these live paper prices in collaboration with CSC Commodities part of the Marex group (Financial conduct Authority regulated, England and Wales). 
                                        Prices are updated every 15min, for an accurate, individual quote please contact us.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Nav tabs --> */}
                        {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Home</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Profile</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="messages-tab" data-bs-toggle="tab" data-bs-target="#messages" type="button" role="tab" aria-controls="messages" aria-selected="false">Messages</button>
                        </li>
                        </ul> */}

                        {/* <!-- Tab panes --> */}
                        {/* <div className="tab-content">
                        <div className="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab" tabindex="0"><Chart/></div>
                        <div className="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab" tabindex="0"><ChartforPepper/></div>
                        <div className="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab" tabindex="0"><ChartforVLSFO/></div>
                        </div> */}

            </div>
        </div>

        
          :  <>
          <h2 className='display-6 py-5 px-5 text-center'>Login Required!</h2>
          </> 
        }
        </>
    )
}

export default Live