import React, { useEffect, useState } from 'react'
import Header from '../components/header'
import { useNavigate } from "react-router-dom";

const Benchmark = () => {

    const usenav = useNavigate();

    const [formData, setFormData] = useState({
        vessel: '',
        port: '',
        laycan: '',
        grade_of_fuel: '',
        paper_index: '',
        bunker_price_target: '',
      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await fetch('/benchmark-form-details', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            console.log('Data inserted successfully');
            setFormData({
                vessel: '',
                port: '',
                laycan: '',
                grade_of_fuel: '',
                paper_index: '',
                bunker_price_target: '',
              });
            window.alert("Data Saved!")
            usenav('/live-prices');
            // Optionally, you can reset the form or perform other actions after a successful request
          } else {
            window.alert("Failed to insert data")
            console.error('Failed to insert data');
          }
        } catch (error) {
          console.error('Error posting data:', error.message);
          
        }
      };
    

    return (
        <>
            <Header />
            <div style={{ backgroundColor: "#00001c" }}>
                {/* volatility section  */}
                {/* <div>
                    <div className='row mx-3 text-align-justify market-top mx-lg-5 px-0'>
                        <div style={{ paddingTop: "40px" }} className='col-md-6 col-12 blw-swipe px-0 text-white first-sec'>
                            <h1 className='mb-4'>Benchmark</h1>
                            <p className='py-0'>
                            Working with some of the most well respected trading houses/ banks/ FCA regulated traders we are able to use our network to tailor make your credit needs when purchasing bunkers or paper. With our risk management team we are able to suit the needs of each individual making purchases in a fully transparent way.
                            </p>
                        </div>
                        <div className='col-md-6 col-12 position-relative'>
                            <div className='text-center text-md-end position-relative'>
                                <img className='credit-val' src="./images/credit_f.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* Section Divider  */}
                <div className='w-100' style={{ backgroundColor: "#FF8C00", height: "0.2rem" }}></div>



                <div style={{ padding: "4rem 0" }} className='align-items-center justify-content-center'>

                    <div className='px-0 text-white text-center mx-3 credit-txt mx-lg-auto mb-5'>
                        <h1 className='mb-4'>Benchmark Form</h1>
                        {/* <p className='py-0'>Please complete our form below and one of our risk management brokers will be with you.</p> */}
                    </div>

                    <div style={{ border: "2px solid white", borderRadius: "10px", boxShadow: "rgb(255 255 255 / 35%) 0px 5px 15px" }} className='credit-form mx-3 mx-lg-auto p-4'>
                        <div className='row m-0 p-0'>
                            <div className='col-lg-6 reg-img'>

                            </div>
                            <div className='col-lg-6'>
                                <div className='ms-3'>
                                    {/* <p className='text-white' style={{ fontSize: "30px" }}>Benchmark Form</p> */}
                                    <form className='pt-3 text-white' onSubmit={handleSubmit}>
                                        {/* <div className='mb-4'>
                                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Type of company*</p>
                                            <select style={{ backgroundColor: "#00001c", border: "1px solid #ff8c00" }}onChange={handleChange} value={formData.typeofcompany} id='typeofcompany' name='typeofcompany'  className='w-100 p-1 text-white'>
                                                <option value="null">Ship Owner</option>
                                                <option value="Charter Operator">Charter Operator</option>
                                                <option value="Trader">Trader</option>
                                                <option value="Broker">Broker</option>
                                                <option value="Physical Supplier">Physical Supplier</option>
                                            </select>
                                        </div> */}
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Vessel*</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.vessel} id='vessel' name='vessel' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Port*</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.port} id='port' name='port' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Laycan*</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.laycan} id='laycan' name='laycan' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Grade of fuel*</p>
                                            <select style={{ backgroundColor: "#00001c", border: "1px solid #ff8c00" }}onChange={handleChange} value={formData.grade_of_fuel} id='grade_of_fuel' name='grade_of_fuel' className='w-100 p-1 text-white'>
                                                <option value="null">Select Grade</option>
                                                <option value="VSLFO 0.5%">VSLFO 0.5%</option>
                                                <option value="LSMGO 0.1%">LSMGO 0.1%</option>
                                                <option value="MGO 0.5%">MGO 0.5%</option>
                                                <option value="HFO 3.5%">HFO 3.5%</option>
                                            </select>
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Paper Index*</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.paper_index} id='paper_index' name='paper_index' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Bunker Price Target*</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.bunker_price_target} id='bunker_price_target' name='bunker_price_target' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div style={{ paddingTop: "0.8rem" }}>
                                            <button type="submit" style={{ backgroundColor: "#ff8c00" }} className="btn-11 nav-but" ><span style={{ zIndex: 3, position: 'relative' }}>Submit</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}

export default Benchmark