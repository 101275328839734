import React from 'react';
import Modal from 'react-bootstrap/Modal';
import "../home.css"
import { useNavigate } from 'react-router-dom';



function MyVerticallyCenteredModal(props) {

    // const nevigate = useNavigate();


    async function handelSubmit(e) {
        e.preventDefault()

        let { name, company, position, email, phone, password } = e.target

        await fetch("/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name.value,
                company: company.value,
                position: position.value,
                email: email.value,
                phone: phone.value,
                password: password.value
            })
        }).then(async (res) => {
            if (res.status === 301) {
                alert(await res.text())
                window.location.reload();
            } else {
                alert(await res.text())
                window.location.reload();
            }
        })

    }



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body style={{ border: "2px solid white", borderRadius: "10px" }} className='row m-0 p-0 position-relative'>
                <div onClick={props.onHide} className='position-absolute cross'>
                    <img className='position-absolute' src="./images/cross.png" alt="" />
                </div>
                <div style={{ borderRadius: "10px 0 0 10px " }} className='col-lg-6 d-none d-lg-block m-0 px-0 reg-img'>
                </div>
                <div style={{ backgroundColor: "#00001c", borderRadius: "0 10px 10px 0" }} className='col-lg-6 m-0 px-4 pt-5'>
                    <p className='text-white' style={{ fontSize: "30px" }}><span style={{ borderBottom: "5px solid #ff8c00", paddingBottom: "8px" }}>Register</span> with us</p>
                    <form onSubmit={handelSubmit} className='pt-3 text-white'>
                        <div className='mb-4'>
                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Name*</p>
                            <input style={{ background: "transparent", border: "1px solid #ff8c00" }} name='name' className='w-100 text-white' type="text" />
                        </div>
                        <div className='mb-4'>
                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Company Name*</p>
                            <input style={{ background: "transparent", border: "1px solid #ff8c00" }} name='company' className='w-100 text-white' type="text" />
                        </div>
                        <div className='mb-4'>
                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Position*</p>
                            <input style={{ background: "transparent", border: "1px solid #ff8c00" }} name='position' className='w-100 text-white' type="text" />
                        </div>
                        <div className='mb-4'>
                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Email*</p>
                            <input style={{ background: "transparent", border: "1px solid #ff8c00" }} name='email' className='w-100 text-white' type="email" />
                        </div>
                        <div className='mb-4'>
                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Phone Number*</p>
                            <input style={{ background: "transparent", border: "1px solid #ff8c00" }} name='phone' className='w-100 text-white' type="text" />
                        </div>
                        <div className='mb-4'>
                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Password*</p>
                            <input style={{ background: "transparent", border: "1px solid #ff8c00" }} name='password' className='w-100 text-white' type="password" />
                        </div>
                        <div style={{ paddingTop: "0.8rem" }}>
                            <button type='submit' style={{ backgroundColor: "#ff8c00" }} className="btn-11 nav-but"><span style={{ zIndex: 3, position: 'relative' }}>Register</span></button>
                        </div>
                        <div className='pt-4'>
                            <p>Already have an account? <span onClick={props.onHide} style={{ color: "#ff8c00", cursor: "pointer" }}> Sign in</span></p>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default function Register() {
    const [show, setShow] = React.useState(true);

    return (
        <>
            <MyVerticallyCenteredModal
                show={show}
                onHide={() => setShow(false)}
            />
        </>
    );
}
