import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/home';
import Footer from './components/footer';
import Bunker from './pages/bunker-price';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Live from './pages/live-price';
import Credit from './pages/credit';
import Market from './pages/market';
import Details from './pages/details';
import Supply from './pages/supplier';
import Credittest from './pages/credittest';
import CheckLivePrice from './pages/CheckLivePrice';
import Headertest from './components/headertest';
import { LoginContextProvider } from './components/ContextProvider/Context';
import Benchmark from './pages/benchmark';


function App() {



  return (
    <div className="App">
    <LoginContextProvider>
      <Router>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/bunker" element={<Bunker />} />
          <Route path='/live-prices' element={<Live />} />
          <Route path='/credit' element={<Credit />} />
          <Route path='/market' element={<Market />} />
          {/* <Route path='/details' element={<Details />} /> */}
          <Route path='/supplier' element={<Supply />} />
          <Route path='/credittest' element={<Credittest />} />
          <Route path='/checkLivePrice' element={<CheckLivePrice />} />
          <Route path='/testhead' element={<Headertest />} />
          <Route path='/benchmark' element={<Benchmark />} />
        </Routes>
      </Router>
      <Footer />
      </LoginContextProvider>
    </div>
  );
}

export default App;
