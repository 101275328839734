import React, { useContext, useEffect, useState } from 'react'
import Login from "../components/login"
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../components/ContextProvider/Context';


const Header = () => {
    const [data, setData] = useState(false);
  const { logindata, setLoginData, isLogged, setLogged } = useContext(LoginContext);

  const usenav = useNavigate();

  const DashboardValid = async () => {
    let token = localStorage.getItem('usersdatatoken');
    const res = await fetch('/validuser', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    const data = await res.json();

    if (data.status === 401 || !data) {
      // console.log('Error Page redirect');
      setLogged(false); // User not logged in
    } else {
      // console.log('User Verified');
      setLoginData(data);
      setLogged(true); // User is logged in
    //   window.location.reload();
    }
  };

  useEffect(() => {
    // setTimeout(() => {
      DashboardValid();
      setData(true);
    // }, 2000);
  }, []);

  const logoutUser = async () => {
    let token = localStorage.getItem('usersdatatoken');
    const res = await fetch('/logout', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'Accept': 'application/json'
      },
      credentials: 'include'
    });

    const data = await res.json();
    // console.log(data);

    if (data.status === 201) {
      // console.log('Logout User!');
      localStorage.removeItem('usersdatatoken');
      setLoginData(null);
      setLogged(false); // User is not logged in after logout
      window.location.reload();
    } else {
      console.log('Error');
    }
  };
  
    return (
      <div className='z-index-9 headerglobal' style={{ backgroundColor: "#00001c" }}>
        <div className="d-flex d-md-none justify-content-end py-2">
          <div>
          <a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='https://www.linkedin.com/company/northseatrading/'><img className="me-2" width={20} src="./images/linkedin.png" alt="" /></a>
          </div>
          <div>
          <a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='https://twitter.com/trading_ns'><img style={{background: "white", borderRadius: "5px", width: "18px"}} className="me-2" width={23} src="./images/social-media.png" alt="" /></a>
          </div>
          <div>
          <a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='https://www.youtube.com/channel/UCSiPs66HQEXAky7P5toQ_iw'><img className="me-2" width={18} src="./images/youtube.png" alt="" /></a>
          </div>
          <div>
          {/* <a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='http://facebook.com/northseatrading'><img className="me-2" width={18} src="./images/facebook.png" alt="" /></a> */}
          </div>
        </div>
        <div className="d-flex d-md-none justify-content-between mx-lg-5 mx-4 py-4">
          <div>
            <img width={120} src="./images/logo.png" alt="" />
          </div>
              {!isLogged && <li style={{zIndex:"9", listStyle: "none"}} className="text-white "><Login /></li>}
              {isLogged && <li style={{zIndex:"9",  listStyle: "none"}} className="text-white "><button onClick={logoutUser}>Logout</button></li>}
          {/* <Login /> */}
        </div>
        <div className="d-md-flex d-none justify-content-between mx-lg-5 mx-4 py-4">
          <div>
            <img src="./images/logo.png" alt="" />
            <h6 style={{color: "#ff8c00", width: "100%"}}>Real Time Brokerage</h6>
          </div>
          <div>
            <ul style={{ listStyle: "none" }} className="d-flex">
              <li>
              <a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='https://www.linkedin.com/company/northseatrading/'><img className="me-3" width={25} src="./images/linkedin.svg" alt="" /></a>
              </li>
              <li>
              <a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='https://twitter.com/trading_ns'><img style={{background: "white", borderRadius: "5px"}} className="me-3" width={25} src="./images/social-media.png" alt="" /></a>
              </li>
              <li>
              <a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='https://www.youtube.com/channel/UCSiPs66HQEXAky7P5toQ_iw'><img className="me-3" width={25} src="./images/youtube.svg" alt="" /></a>
              </li>
              <li>
              {/* <a target='__blank' style={{color: "inherit", textDecoration: "none"}} href='http://facebook.com/northseatrading'><img className="me-3" width={25} src="./images/facebook.svg" alt="" /></a> */}
              </li>
                {!isLogged && <li style={{zIndex:"9"}} className="text-white header-login-li"><Login /></li>}
                {isLogged && <li style={{zIndex:"9"}} className="text-white "><button onClick={logoutUser}>Logout</button></li>}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  
  export default Header;
  

