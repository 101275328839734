import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "../home.css"
import Register from "./register"
import axios from "axios"

function MyVerticallyCenteredModal(props) {
    


    const [inpval, setInpval] = useState({
        email: "",
        password: ""
      });
    
      // console.log(inpval);
    
    
      const setVal = (e) =>{
        // console.log(e.target.value);
        const {name, value} = e.target;
        setInpval (()=>{
          return{
            ...inpval, 
            [name]:value
          }
        })
      };

    const loginuser = async(e)=>{
        e.preventDefault();
    
        const {email, password} = inpval;
    
        if(email ===""){
          alert("Please Enter Your Email");
        }else if(!email.includes("@")){
          alert("Please Enter Valid Email");
        }else if(password ===""){
          alert("Please Enter Your Password");
        }else if(password.length < 6){
          alert ("Password must be 6 Character")
        }else{
          // console.log("User Login Sucessful!");
    
          const data = await fetch("/login",{
            method:"POST",
            headers:{
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              email, password
            })
          });
    
          const res = await data.json();
          console.log(res);
          // window.alert()
          // console.log(res.status);
    
          if(res.status === 201){
            // alert("User Registration Done");
            localStorage.setItem("usersdatatoken", res.result.token);
            window.alert("Login Sucessfull!!")
            window.location.reload();
            // usenav("/profile")
            // setInpval({...inpval, email:"", password:""})
          }
          if(res.status === 404){
            window.alert("User Not Found")
            setInpval({...inpval, email:"", password:""})
          }
          if(res.status === 426){
          window.alert("Your Status is Pending. Please contact to the Admin!!")
          setInpval({...inpval, email:"", password:""})
          }
          else if(res.status === 422){
            window.alert("Invalid Credentials")
            setInpval({...inpval, email:"", password:""})
          }
        }
      }
    
    
    
      const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
          email: data.get('email'),
          password: data.get('password'),
        });
      };







    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body style={{ border: "2px solid white", borderRadius: "10px" }} className='row m-0 p-0 position-relative'>
                <div onClick={props.onHide} className='position-absolute cross'>
                    <img className='position-absolute' src="./images/cross.png" alt="" />
                </div>
                <div className='col-lg-6 m-0 px-0'>
                    <div>
                        <img className='w-100' style={{ borderRadius: "10px 0 0 10px" }} src="./images/ship2.png" alt="" />
                    </div>
                </div>
                <div style={{ backgroundColor: "#00001c", borderRadius: "0 10px 10px 0" }} className='col-lg-6 m-0 px-4 pt-5'>
                    <p className='text-white' style={{ fontSize: "30px" }}><span style={{ borderBottom: "5px solid #ff8c00", paddingBottom: "8px" }}>Login to</span> account</p>
                    <form onSubmit={handleSubmit} className='pt-3 text-white'>
                        <div className='mb-4'>
                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Email*</p>
                            <input id="email"  name="email" value={inpval.email} onChange={setVal} style={{ background: "transparent",color: "white" ,border: "1px solid #ff8c00" }} className='w-100' type="email"  />
                        </div>
                        <div className='mb-4'>
                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Password*</p>
                            <input name="password" value={inpval.password} onChange={setVal} style={{ background: "transparent",color: "white" ,border: "1px solid #ff8c00" }} className='w-100' type="password"  />
                        </div>
                        <div style={{ paddingTop: "0.8rem" }}>
                            <button onClick={loginuser} style={{ backgroundColor: "#ff8c00" }} className="btn-11 nav-but"><span style={{ zIndex: 3, position: 'relative' }}>Login</span></button>
                        </div>
                        <div className='pt-4'>
                            <p>Don't have an account?<span onClick={() => {
                                props.reg(false)
                            }} style={{ color: "#ff8c00", cursor: "pointer"  }}> Register with us</span></p>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default function App() {
    const [modalShow, setModalShow] = React.useState(false);
    const [turn, setTurn] = React.useState(true)

    return (
        < >
            <button onClick={() => {
                setTurn(true)
                setModalShow(true)
            }} style={{zIndex: 10 }} className="login-button-main btn-11 nav-but"><span style={{ zIndex: 3, position: 'relative' }}>Login</span></button>

            {turn ? <MyVerticallyCenteredModal
                show={modalShow}
                reg={setTurn}
                onHide={() => setModalShow(false)}
            /> : <Register />}
        </>
    );
}
