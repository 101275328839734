import React from 'react'
import Header from '../components/header'
import "../home.css"
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'

const Market = () => {
    const [articleData, setArticleData] = useState([])
    async function getArticleData() {
        let data = await fetch(`/getarticles`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        })
    
        let res = await data.json()
        console.log(res)
    
        setArticleData(res)
      }

      useEffect(() => {
        getArticleData()
      }, []);
    return (
        <>
            <Header />
            <div style={{ backgroundColor: "#00001c" }}>
                {/* volatility section  */}
                {/* volatility section  */}
                <div>
                    <div className='row market-top just mx-3 text-align-justify mx-lg-5 px-0'>
                        <div style={{ paddingTop: "40px" }} className='col-md-6 text-center text-md-start col-12 blw-swipe px-0 text-white first-sec'>
                            <h1 className='mb-4'>Market Intelligence</h1>
                            <p className='py-0'>
                                Northsea Trading working with our partners worldwide offer guidance, 
                                information, and compliance solutions for you to gain a full prospective of the market when placing or planning your fuels requirements.
                            </p>

                        </div>
                        <div className='col-md-6 col-12 position-relative'>
                            <div className='text-center text-md-end'>
                                <img className='market' src="./images/market_f.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section Divider  */}
                <div className='w-100' style={{ backgroundColor: "#FF8C00", height: "0.2rem" }}></div>


                {/* Intel Section  */}
                
                <div style={{ padding: "5rem 0" }} className='row mx-3 mx-lg-5 px-0'>

                {
                    articleData ? articleData.map((element, index) => {
                        return(
                            <div className='col-md-6 col-12 my-4 pad-mar'>
                            {/* <a target='__blank' href={`http://localhost:5000/${element.url}`} style={{textDecoration: "none", color: "inherit"}} className='position-relative'> */}
                            <a target='__blank' href={`/${element.url}`} style={{textDecoration: "none", color: "inherit"}} className='position-relative'>
                                <div style={{ backgroundImage: `url(${element.image})` }} className='image '>
                                    <span className='px-2'>
                                        <p className='fs-2 text-white'>{element.title}</p>
                                        <p className='fs-8 text-white'>{element.date}</p>
                                        <p className='text-white market-text'>{element.description}</p>
                                        <div className="card-date">
                                            {/* <Link target='__blank' to={`http://localhost:5000/${element.url}`} style={{ color: "#FF8C00" }} className='mb-3 card-day'>Read More <img className='mx-2' src="./images/right-arrow.png" alt="" /></Link> */}
                                            <Link target='__blank' to={`/${element.url}`} style={{ color: "#ff8c00" }} className='mb-3 onhov-underline'>Read More <img className='mx-2' src="./images/right-arrow.png" alt="" /></Link>
                                        </div>
                                    </span>
                                </div>
                            </a>
                        </div> 
                        )
                    })
                    :null
                }
                    
                    {/* <div className='col-md-6 col-12 my-4 pad-mar'>
                        <div className='position-relative'>
                            <div style={{ backgroundImage: 'url("./images/intel1.png")' }} className='image zoom-out'>
                                <span className='px-2'>
                                    <p className='fs-2 text-white'>Lorem Ipsum</p>
                                    <p className='text-white market-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit earum sit delectus blanditiis molestiae. Sed, deleniti harum! Veniam magnam assumenda recusandae! Nam voluptatum saepe expedita quia, quidem id quam iste?</p>
                                    <div className="card-date">
                                        <Link to={'/details'} style={{ color: "#FF8C00" }} className='mb-3 card-day'>Read More <img className='mx-2' src="./images/right-arrow.png" alt="" /></Link>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-12 my-4 pad-mar'>
                        <div className='position-relative'>
                            <div style={{ backgroundImage: 'url("./images/intel2.png")' }} className='image zoom-out'>
                                <span className='px-2'>
                                    <p className='fs-2 text-white'>Lorem Ipsum</p>
                                    <p className='text-white market-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit earum sit delectus blanditiis molestiae. Sed, deleniti harum! Veniam magnam assumenda recusandae! Nam voluptatum saepe expedita quia, quidem id quam iste?</p>
                                    <div className="card-date">
                                        <Link to={'/details'} style={{ color: "#FF8C00" }} className='mb-3 card-day'>Read More <img className='mx-2' src="./images/right-arrow.png" alt="" /></Link>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-12 my-4 pad-mar'>
                        <div className='position-relative'>
                            <div style={{ backgroundImage: 'url("./images/intel3.png")' }} className='image zoom-out'>
                                <span className='px-2'>
                                    <p className='fs-2 text-white'>Lorem Ipsum</p>
                                    <p className='text-white market-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit earum sit delectus blanditiis molestiae. Sed, deleniti harum! Veniam magnam assumenda recusandae! Nam voluptatum saepe expedita quia, quidem id quam iste?</p>
                                    <div className="card-date">
                                        <Link to={'/details'} style={{ color: "#FF8C00" }} className='mb-3 card-day'>Read More <img className='mx-2' src="./images/right-arrow.png" alt="" /></Link>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-12 my-4 pad-mar'>
                        <div className='position-relative'>
                            <div style={{ backgroundImage: 'url("./images/intel1.png")' }} className='image zoom-out'>
                                <span className='px-2'>
                                    <p className='fs-2 text-white'>Lorem Ipsum</p>
                                    <p className='text-white market-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit earum sit delectus blanditiis molestiae. Sed, deleniti harum! Veniam magnam assumenda recusandae! Nam voluptatum saepe expedita quia, quidem id quam iste?</p>
                                    <div className="card-date">
                                        <Link to={`/details`} style={{ color: "#FF8C00" }} className='mb-3 card-day'>Read More <img className='mx-2' src="./images/right-arrow.png" alt="" /></Link>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-12 my-4 pad-mar'>
                        <div className='position-relative'>
                            <div style={{ backgroundImage: 'url("./images/intel1.png")' }} className='image zoom-out'>
                                <span className='px-2'>
                                    <p className='fs-2 text-white'>Lorem Ipsum</p>
                                    <p className='text-white market-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit earum sit delectus blanditiis molestiae. Sed, deleniti harum! Veniam magnam assumenda recusandae! Nam voluptatum saepe expedita quia, quidem id quam iste?</p>
                                    <div className="card-date">
                                        <Link to={`/details`} style={{ color: "#FF8C00" }} className='mb-3 card-day'>Read More <img className='mx-2' src="./images/right-arrow.png" alt="" /></Link>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-12 my-4 pad-mar'>
                        <div className='position-relative'>
                            <div style={{ backgroundImage: 'url("./images/intel2.png")' }} className='image zoom-out'>
                                <span className='px-2'>
                                    <p className='fs-2 text-white'>Lorem Ipsum</p>
                                    <p className='text-white market-text'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit earum sit delectus blanditiis molestiae. Sed, deleniti harum! Veniam magnam assumenda recusandae! Nam voluptatum saepe expedita quia, quidem id quam iste?</p>
                                    <div className="card-date">
                                        <Link to={'/details'} style={{ color: "#FF8C00" }} className='mb-3 card-day'>Read More <img className='mx-2' src="./images/right-arrow.png" alt="" /></Link>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div> */}
                </div>


            </div>
        </>
    )
}

export default Market