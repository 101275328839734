// import React, { useEffect, useState } from "react";
// import { Chart } from "react-google-charts";



// export const data = [
//   ["Days", "Singapore", "Rotterdam", "Fujairah"],
//   ["20 Jan", 440, 400, 500],
//   ["21 Jan", 450, 420, 550],
//   ["22 Jan", 460, 425, 600],
//   ["23 Jan", 470, 440, 700],
// ];

// // export const data = [
// //   ["Days", "VLSFO", "HFO", "MGO"],
// //   ["20 Jan", 1000, 400, 500],
// //   ["21 Jan", 1170, 460, 600],
// //   ["22 Jan", 660, 1120, 800],
// //   ["23 Jan", 1030, 540, 700],
// // ];

// export const options = {
//   // isStacked: true,
//   legend: { position: "top"},
//   // legend: "true",
//   hAxis: { title: "Date", titleTextStyle: { color: "white", bold: true }, color:"red" },
//   vAxis: { title: "Price Range", titleTextStyle: { color: "white", bold: true }, minValue: 0 },
//   chartArea: { width: "80%" },
//   pointsVisible: true,
//   // backgroundColor: "bla",
//   color: "white"
// };

// export default function Chart1() {
//     const [data, setData] = useState([]);

//     useEffect(() => {
//       async function getPrices() {
//         try {
//           let response = await fetch(`/getgraphmgodata`, {
//             method: "GET",
//             headers: {
//               "Content-Type": "application/json"
//             }
//           });

//           let result = await response.json();
//           console.log(result);

//           setData(result);
//         } catch (error) {
//           console.error("Error fetching data:", error);
//         }
//       }

//       getPrices();
//     }, []);

//   return (
//     <Chart
//       chartType="AreaChart"
//       width="100%"
//       style={{backgroundColor: "black"}}
//       height="400px"
//       data={data}
//       options={options}
//     />
//   );
// }

import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";


export const data = [
  ["Days", "Singapore"],
  ["20 Jan", 440,],
  ["21 Jan", 450,],
  ["22 Jan", 460,],
  ["23 Jan", 470,],
];

export default function Chart3() {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await fetch("/getgraphmgodata", {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });

        let result = await response.json();
        console.log(result);

        const uniqueCountries = [...new Set(result.map(entry => entry.countryName))];
        const header = ["Days", ...uniqueCountries];
        const groupedData = result.reduce((acc, entry) => {
          const { date, countryName, VLSFO } = entry;
          if (!acc[date]) {
            acc[date] = { date };
          }
          acc[date][countryName] = VLSFO;
          return acc;
        }, {});

        // Converting grouped data to the final format
        const finalData = [header, ...Object.values(groupedData).map(entry => Object.values(entry))];

        // setChartData(finalData);

        // const formattedData = result.map(entry => [entry.date, entry.MGO]);
        // const finalData = [["Days", "Singapore"], ...formattedData];
        const today = new Date();
        const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, today.getDate());
        
        finalData[1][0] = `${twoMonthsAgo.getDate()} ${getMonthName(twoMonthsAgo.getMonth())}`; // 2 months ago
        finalData[2][0] = `${oneMonthAgo.getDate()} ${getMonthName(oneMonthAgo.getMonth())}`; // 1 month ago
        finalData[3][0] = `${today.getDate()} ${getMonthName(today.getMonth())}`; // Today's date
        console.log(finalData)
        // Assuming your result has the same structure as the initial data
        setChartData(finalData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  const options = {
    // isStacked: true,
    legend: { position: "top"},
    // legend: "true",
    hAxis: { title: "Date", titleTextStyle: { color: "white", bold: true }, color: "red" },
    vAxis: { title: "Price Range", titleTextStyle: { color: "white", bold: true }, minValue: 0 },
    chartArea: { width: "80%" },
    pointsVisible: true,
    // backgroundColor: "bla",
    color: "white"
  };
  



  return (
    <Chart
      chartType="AreaChart"
      width="100%"
      style={{backgroundColor: "black"}}
      height="400px"
      data={chartData || data} // Use the fetched data or fallback to the static data
      // data={data} // Use the fetched data or fallback to the static data
      
      options={options}
    />
  );
}

function getMonthName(monthIndex) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[monthIndex];
}