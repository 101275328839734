import React, { useEffect, useState } from 'react'
import Header from '../components/header'
import { useNavigate } from "react-router-dom";
import '../index.css';

const Credit = () => {

    const [formData, setFormData] = useState({
        typeofcompany: '',
        creditperiod: '',
        companyname: '',
        companyaddress: '',
        position: '',
        email: '',
        phoneno: '',
      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await fetch('/credit-form-details', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            console.log('Data inserted successfully');
            setFormData({
                typeofcompany: '',
                creditperiod: '',
                companyname: '',
                companyaddress: '',
                position: '',
                email: '',
                phoneno: '',
              });
            window.alert("Data Saved!")
            // Optionally, you can reset the form or perform other actions after a successful request
          } else {
            window.alert("Failed to insert data")
            console.error('Failed to insert data');
          }
        } catch (error) {
          console.error('Error posting data:', error.message);
          
        }
      };
    

    return (
        <>
            <Header />
            <div style={{ backgroundColor: "#00001c" }}>
                {/* volatility section  */}
                <div>
                    <div className='row mx-3 text-align-justify market-top mx-lg-5 px-0'>
                        <div style={{ paddingTop: "40px" }} className='col-md-6 col-12 blw-swipe px-0 text-white first-sec'>
                            <h1 className='mb-4'>Credit</h1>
                            <p className='py-0'>
                            Working with some of the most well respected trading houses/ banks/ FCA regulated traders we are able to use our network to tailor make your credit needs when purchasing bunkers or paper. With our risk management team we are able to suit the needs of each individual making purchases in a fully transparent way.
                            </p>
                        </div>
                        <div className='col-md-6 col-12 position-relative'>
                            <div className='text-center text-md-end position-relative'>
                                <img className='credit-val' src="./images/credit_f.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section Divider  */}
                <div className='w-100' style={{ backgroundColor: "#FF8C00", height: "0.2rem" }}></div>



                <div style={{ padding: "4rem 0" }} className='align-items-center justify-content-center'>

                    <div className='px-0 text-white text-center mx-3 credit-txt mx-lg-auto mb-5'>
                        <h1 className='mb-4'>Details</h1>
                        <p className='py-0'>Please complete our form below and one of our risk management brokers will be with you.</p>
                    </div>

                    <div style={{ border: "2px solid white", borderRadius: "10px", boxShadow: "rgb(255 255 255 / 35%) 0px 5px 15px" }} className='credit-form mx-3 mx-lg-auto p-4'>
                        <div className='row m-0 p-0'>
                            <div className='col-lg-6 reg-img'>

                            </div>
                            <div className='col-lg-6'>
                                <div className='ms-3'>
                                    <p className='text-white' style={{ fontSize: "30px" }}>Credit Form</p>
                                    <form className='pt-3 text-white' onSubmit={handleSubmit}>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Type of company*</p>
                                            <select style={{ backgroundColor: "#00001c", border: "1px solid #ff8c00" }}onChange={handleChange} value={formData.typeofcompany} id='typeofcompany' name='typeofcompany'  className='w-100 p-1 text-white'>
                                                <option value="null">Ship Owner</option>
                                                <option value="Charter Operator">Charter Operator</option>
                                                <option value="Trader">Trader</option>
                                                <option value="Broker">Broker</option>
                                                <option value="Physical Supplier">Physical Supplier</option>
                                            </select>
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px" }} className='p-0 mb-1'>Credit Period*</p>
                                            <select style={{ backgroundColor: "#00001c", border: "1px solid #ff8c00" }}onChange={handleChange} value={formData.creditperiod} id='creditperiod' name='creditperiod' className='w-100 p-1 text-white'>
                                                <option value="null">Select Period</option>
                                                <option value="21ddd">21ddd</option>
                                                <option value="30ddd">30ddd</option>
                                                <option value="45ddd">45ddd</option>
                                                <option value="60ddd">60ddd</option>
                                                <option value="90ddd">90ddd</option>
                                            </select>
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Company Name*</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.companyname} id='companyname' name='companyname' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Company Address*</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.companyaddress} id='companyaddress' name='companyaddress' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Position*</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.position} id='position' name='position' onChange={handleChange} className='w-100 p-1' type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Email*</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.email} id='email' name='email' onChange={handleChange} className='w-100 p-1' type="email" />
                                        </div>
                                        <div className='mb-4'>
                                            <p style={{ fontSize: "16px"}} className='p-0 mb-1'>Phone No*</p>
                                            <input style={{ background: "transparent", color: "white" , border: "1px solid #ff8c00" }}value={formData.phoneno} id='phoneno' name='phoneno' onChange={handleChange} className='w-100 p-1' type="number" />
                                        </div>
                                        <div style={{ paddingTop: "0.8rem" }}>
                                            <button type="submit" style={{ backgroundColor: "#ff8c00" }} className="btn-11 nav-but" ><span style={{ zIndex: 3, position: 'relative' }}>Submit</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='fin_part_Sec pb-4'>
                    <div className='text-center'>
                        <h3 className='text-white'>Finance Partner</h3>
                        <div className='fin_part_image'>
                            <img className='fin_part_img' src="./images/Synsol_Logo.webp" alt="" />
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default Credit